import React, { Component } from "react";


class SectionReflectionEducation extends Component {

  render() {
    return (
    <div>
        <div className="section-reflection-on-education">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-6">
                        <h2 className="title-education-center">¿Qué debe saber un docente de hoy para que sus estudiantes
                            aprendan?</h2>
                        <ul>
                            <li>Como punto de partida reflexionar sobre las condiciones y motivaciones de las nuevas tecnologías para la enseñanza y el aprendizaje.</li>
                            <li>Desafiar los conceptos y pensamientos tradicionales sobre la relación existente entre la tecnología y la comunicación aplicada a la educación.</li>
                            <li>La brecha entre humano y tecnología no es tan grande, así como las sociedades transforman los entornos tecnológicos, estos también modifican a las sociedades en cuanto a su cultura, hábitos, etc.</li>
                            {/* <li><strong className="texto-azul">“ las nuevas tecnologías se han convertido en un problema educativo, un desafío, una oportunidad, un riesgo, una necesidad.[Burbulles y Callister]</strong></li> */}
                            <li className="strong">"las nuevas tecnologías se han convertido en un problema educativo, un desafío, una oportunidad, un riesgo, una necesidad. [Burbulles y Callister]"</li>
                        </ul>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-6">
                        <img className="img-education" src={require("../../assets/images/muro.jpg")} alt="" />
                    </div>
                </div>
            </div>
        </div>
    </div>
    );
  }
}
 
export default SectionReflectionEducation;