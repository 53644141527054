import React, { Component } from "react";
import { Link } from "react-router-dom";

class SocialEducation extends Component {

  // Redirect and Scroll
  // Funcion para regresar a la pagina anterior y posicionarse
  // en la seccion de Informacion.
  handleClick(event) {
    event.preventDefault();    
    const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop) ;        
    window.history.back(scrollToRef);
  }  

  render() {
    return (
      <div className="socialeducation">
           
           {/* <Link to={"/"}><span className="link-regresar fa fa-arrow-circle-left"></span></Link> */}
           <Link to="/#"  onClick={this.handleClick}><span className="link-regresar fa fa-arrow-circle-left"></span></Link>
           
           <div className="container">
                <h3>La educación social en la Argentina</h3>
                <div className="row">
                    <div className="col-md-12">
                        <b className="cursiva-azul">"La educación es el arma más poderosa que puedes usar para cambiar el mundo"</b>
                        <h5>Nelson Mandela</h5>
                        <hr/>
                        <h4>La Educación Social como una práctica de la Pedagogía Social</h4>
                        <p>La Educación Social como una práctica de la Pedagogía Social se podría definir como la práctica educativa para contribuir a lograr la armonía, la integración, el equilibrio, la formación de la persona en todos los ámbitos, para así colaborar a su desarrollo e igualdad de oportunidades.</p>
                        <p>La educación pública tiene severos problemas de calidad y hoy no garantiza la igualdad de oportunidades. ¿Pero que es la igualdad de oportunidades? , ¿No sería más correcto hablar de equidad de oportunidades? Ampliemos dichos conceptos para evitar dar una interpretación errónea o falsa en cuanto a las demandas entre la educación social y las políticas públicas.</p>
                        <p>Igualdad y Equidad suelen utilizarse habitualmente como sinónimos, sin embargo representan conceptos connotativamente diferentes.</p>
                        <p>La igualdad de oportunidades es el nivel más básico, donde la igualdad se define como la necesidad de garantizar el derecho de todas las personas a elegir cualquier opción existente dentro del sistema educativo. Tiene que ver con una declaración de buenas intenciones, un compromiso con la estructura social y educativa de reconocer la educación como un derecho fundamental y, como resultado, garantizarla, ofreciendo una educación básica obligatoria y gratuita para todos los ciudadanos sin distinción de sexo, cultura u origen social.</p>
                        <p>En tanto, Equidad parte desde la igualdad a la consideración de las diferencias que existen entre los diferentes niveles sociales en cuanto al contexto en el que transita y vive el alumno, ya sea familiar, situación de pobreza, etc. Si comparáramos a un niño el cual posee una buena alimentación con otro el cual no corre con la misma suerte, es bien sabido que este último no tendrá las mismas capacidades de aprendizaje, pues un niño que tiene una buena alimentación, es muy difícil que pueda pensar en otra cosa de la que no sea de alimentarse. La relación es directamente proporcional, cuando más bajo el nivel socioeconómico, mayores problemas de aprendizaje. En conclusión, podríamos formular a equidad como (equidad = igualdad más la sumas de las diferencias).</p>
                        <p>Existe un factor clave que influye directamente en garantizar el derecho a que tenemos todos los ciudadanos a la educación y se trata del dialogo entre la educación social y las políticas públicas.</p>
                        <p>El derecho a la educación muchas veces se ve afectado por el dialogo que se establecen entre los referentes de la educación social y los de las políticas públicas, generalmente porque estos últimos no tienen una concepción de la realizad social por la que se encuentran atravesando los argentinos.  Colectivos sociales reclamando por mejores condiciones en cuanto a: espacios de trabajo donde se imparten clases, inclusión socio socioeducativas, libre elección de cualquier opción existente dentro del sistema educativo, mejoras en los salarios, etc., son las luchas constantes  que el sistema educativo enfrenta a diario y que diferentes sectores  concentra a través de las demandas vinculada al campo educativo. Es importante destacar que tanto el gobierno como los individuos demandan educación.  A continuación se presentara una conceptualización sobre el término “demanda”, siempre haciendo hincapié al  campo educativo.</p>
                    </div>
                </div>    

                <div className="row">    
                    <div className="col-md-12 ">
                        <img src={require("../../assets/images/educacionsocial.png")} alt="" />
                        <br/>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-12">
                        <h4>La demanda </h4>
                        <h5>¿Qué entendemos por demanda?</h5>
                        <p>Las demandas son aquellas luchas que movilizan diferentes actores y sectores para el cumplimiento de derechos. En cuanto se refiere al campo educativo, el término “demanda” tiende a una connotación más específica refiriéndose a todo aquellos que la sociedad y los diversos grupos sociales que la conforman necesitan de la educación, a los nuevos desafíos emergentes de procesos de cambios que viven los argentinos en la actualidad.</p>
                        <p>En la actualidad, la coyuntura entre el sistema educativo y la sociedad es en gran medida mucho más compleja a causa de un conjunto de cambios que se producen a un ritmo acelerado y que pone al sistema educativo ante múltiples dificultades para sostener y responder  a las nuevas demandas de la sociedad.  Las tecnologías cambian constantemente, con ellas las formas en que nos comunicamos que conlleva en nuevos requerimientos en cuanto a la formación de los ciudadanos para la incorporación al campo laboral, significativos cambios en la estructura familiar, pobreza, desempleo. Situaciones que presentan un exceso en las demandas que la sociedad incorpora sobre los sistemas educativos. Todo parece indicar que la educación formal deba tender a sustituir a la familia en una proporción cada vez mayor en la vida del educando; que enseñe los aprendizajes básicos, leer y escribir, respeto por el 
                            medio ambiente, que proporcione educación física y artística, informática, enseñe idiomas y además atienda a la alimentación y saludos de los alumnos. Sin embargo,  en su mayoría,  los centros educacionales no cuentan con los recursos necesarios para tal fin y más aún cuando las políticas que el estado aplica no condicen con el estatus social y económico que se da a los profesionales a cargo de la labor educativa. </p>
                    </div>
                </div>       
                
                <div className="row">
                    <div className="col-md-12">
                        <h4>Desigualdad en el aprendizaje</h4>
                        <p>Una investigación realizada en el año 2001 arrojo datos inquietantes en los cuales se dejaba en evidencia la profunda pobreza educativa que sufrían los ciudadanos argentinos, donde jóvenes y adultos estaban condenados  a un probable futuro de marginación social, política y económica.  El nivel socioeconómico es la principal variable asociada a las desigualdades en los aprendizajes y donde no solo los jóvenes y adultos son afectados, sino que también este fenómeno llega a la etapa temprana de la infancia educativa de los niños.  Hasta este momento de esta investigación, la situación presenta características similares, la población que alguna vez se matriculó en la escuela tiene problemas para completar los niveles básicos de educación formal necesarios para enfrentar la complejidad del mundo actual, más alumnos que asistían a las escuelas hoy ya no lo hacen.  Si bien el panorama es poco alentador,  distintos sectores sociales y políticos están tomando medidas para poder contrarrestar y afrontar de la mejor manera posible esta problemática. </p>
                        <p>No todos los problemas en la educación se deben a factores económicos. Unicef presento un informe sobre la realidad que atraviesan los chicos de entre 10 y 18 años y la preocupante cifras de trabajo infantil, violencia doméstica y bullyng</p>
                        <p> El informe remarca que el abandono escolar "está fuertemente relacionado con la inserción temprana en el mercado de trabajo, sobre todo entre los varones, y el embarazo entre las mujeres". El 15% de los nacimientos en Argentina son de embarazos adolescentes: 6 de cada 10 no son planificados y 1 de cada 10 mujeres abandona la secundaria por tal motivo o porque se aboca al cuidado de sus hijos, hijas, hermanos o hermanas menores.</p>
                    </div>
                </div>              

                <div className="row">
                    <div className="col-md-12">
                        <h4>La demanda de alfabetización en jóvenes y adultos</h4>
                        <p>Las organizaciones sociales tienen un importante protagonismo en las acciones de alfabetización y educación de jóvenes y  adultos en la Argentina. Estas organizaciones han desarrollado proyectos de forma autónoma y con poca o ninguna articulación con el Estado. En otros casos, se han profundizados estos vínculos de diversas formas.</p>
                        <p>En los últimos años fueron creciendo las organizaciones que comenzaron a implementar planes de alfabetización en el marco del Programa "Yo sí puedo". Los inicios de esta tarea en la Argentina se vinculan a zonas muy humildes del gran Buenos Aires, donde se encontraron importantes problemas de analfabetismo.  Actualmente hay una cantidad importante de Centros de Alfabetización y que trae como consecuencia el aumento en cuanto a jóvenes y adultos graduados en el país. En muchos de los municipios, se intenta articular con la escuela de adultos que haya en la zona para la continuidad de los estudios primarios. No obstante, la gran mayoría cuenta con acceso al programa FinES5, una oportunidad más flexible que se desarrolla generalmente en alguna institución del mismo barrio.</p>
                        <p><strong className="cursiva-azul"> El método de alfabetización cubano “Yo sí puedo”, fue creado por el IPLAC (Instituto Pedagógico Latinoamericano y Caribeño) de Cuba con el objetivo de ayudar a los pueblos del Tercer Mundo afectados por esta problemática. Esta herramienta educativa comienza a implementarse en la Argentina en el año 2003 a través de diversos movimientos y organizaciones sociales de base bajo la coordinación de la Fundación UMMEP (Un Mundo Mejor es Posible). </strong></p>
                    </div>
                </div>  

                <div className="row">
                    <div className="col-md-12">
                        <h4>conclusión</h4>
                        <p>Si no recuperamos la idea de que una de nuestras más importantes prioridades  debe ser el de proporcionar a todos la mejor educación posible, la estabilidad social corre un grave peligro.</p>
                        <p>Quizás la acción más importante para conseguir revertir la tendencia que actualmente tienen la educación a distorsionar con la realidad social que viven cada uno de los individuos en estado de pobreza, es el esfuerzo.  Esfuerzo que no solo se sostengan por parte de quienes educan y de docentes y padres que deben estimular a los estudiantes a transitar ese camino, sino también del conjunto de la comunidad y sobre todo del estado para garantizar los derechos básicos como la salud, la alimentación, viviendas, lo que implica por cierto,  vencer la tendencia actual a considerar que el estado moderno debe desprenderse de las que fueron tradicionalmente sus funciones, entre las ya mencionadas, la de garantizar la educación de todos.</p>
                    </div>
                </div>  

                <div className="row">    
                    <div className="col-md-12 ">
                        <img src={require("../../assets/images/muroeducacion.jpg")} alt="" />
                    </div>
                </div>
                <br/>
            </div>
      </div>
    );
  }
}
 
export default SocialEducation;