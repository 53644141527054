import React, { Component } from "react";
import { Link } from "react-router-dom";
import { animateScroll as scroll} from "react-scroll";

import { scroller } from "react-scroll";
import { Element } from "react-scroll";

class ComputersFuture extends Component {

  constructor(props){
    super(props);
    scroll.scrollToTop(); // posicionarme al inicio de la pagina.
  }
  
  handleClick(event) { // funcion para regresar a la pagina anterior.
    event.preventDefault();    
    const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop) ;        
    window.history.back(scrollToRef);
  }  
  
  ActionIrToSection(parameter, event) { // funcion para posicionarme en una determinada seccion de la misma pagina.
    event.preventDefault();    
    scroller.scrollTo(parameter);
  }  
    
  render() {
    return (
      <div className="computersfuture">

        <Element name="section-arriba">
        {/* posicionamiento al comienzo de la pagina */}
        </Element>

        {/* Botones de direccionamiento */}
        <Link to="/#" onClick={this.ActionIrToSection.bind(this, "section-arriba")}><span className="link-arriba fa fa-arrow-circle-up"></span></Link>
        <Link to="/#" onClick={this.handleClick}><span className="link-regresar fa fa-arrow-circle-left"></span></Link>
        <Link to="/#" onClick={this.ActionIrToSection.bind(this, "section-abajo")}><span className="link-abajo fa fa-arrow-circle-down"></span></Link>
       
        <div className="container">
          <h1>Page ComputersFuture</h1>
        </div>

        <Element name="section-abajo">
            {/* posicionamiento al final de la pagina */}
        </Element>
                
      </div>
    );
  }
}
 
export default ComputersFuture;