import React, { Component } from "react";
 
class Navbar extends Component {
  render() {
    return (
      <div>
          <nav className="navbar navbar-expand-lg  navbar-dark bg-dark-black">
              <a className="navbar-brand" href="/"><img className="logo" src={require("../../assets/images/logo.png")} alt="" /></a>
              <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                  aria-expanded="false" aria-label="Toggle navigation">
                  <span className="navbar-toggler-icon"></span>
              </button>
              <div className="collapse navbar-collapse" id="navbarSupportedContent">
                  <ul className="navbar-nav mr-auto">
                      <li className="nav-item active">
                          <a className="nav-link" href="/">Inicio
                              <span className="sr-only">(current)</span>
                          </a>
                      </li>
                      <li className="nav-item">
                          <a className="nav-link" href="#informacion">Información</a>
                      </li>
                      <li className="nav-item">
                          <a className="nav-link" href="#porfolio">Porfolio</a>
                      </li>
                      <li className="nav-item">
                          <a className="nav-link" href="#about">Sobre mi</a>
                      </li>                                            
                      {/* <li className="nav-item dropdown">
                          <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true"
                              aria-expanded="false">
                              Dropdown
                          </a>
                          <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                              <a className="dropdown-item" href="#">Action</a>
                              <a className="dropdown-item" href="#">Another action</a>
                              <div className="dropdown-divider"></div>
                              <a className="dropdown-item" href="#">Something else here</a>
                          </div>
                      </li>
                      <li className="nav-item">
                          <a className="nav-link disabled" href="#">Disabled</a>
                      </li> */}
                  </ul>
              </div>
          </nav>
      </div>
    );
  }
}
 
export default Navbar;