import React, { Component } from "react";
 
class NoFound extends Component {
  render() {
    return (
      <div>
        <h1>Page NoFound</h1>
      </div>
    );
  }
}
 
export default NoFound;