import React, { Component } from "react";
import { Link } from "react-router-dom";


class DigitalRevolution extends Component {

  // Redirect and Scroll
  // Funcion para regresar a la pagina anterior y posicionarse
  // en la seccion de Informacion.
  handleClick(event) {
    event.preventDefault();    
    const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop) ;        
    window.history.back(scrollToRef);
  }  

  render() {
    return (
      <div className="digitalrevolucion">
        
        <Link to="/#" onClick={this.handleClick}><span className="link-regresar fa fa-arrow-circle-left"></span></Link>

        <div className="container">
                <h3 className="cursiva-azul">Es evidente que en la actualidad nos encontramos ante una revolución en materia de comunicación causada por el exceso de información que reproducen los medios tales como la televisión, la radio, periódicos, revistas, y sobre todo por la cantidad de información que fluye por internet. Esta superabundancia de información transforma a la sociedad en cuando a sus ideas, hábitos, costumbres, cambios culturales que traen como consecuencia un desfasaje en la enseñanza de la educación tradicionalista.</h3>
                <div className="row">
                    <div className="col-md-12">
                    <h4>Educación ciencia y tecnología</h4>
                        <h5>La relación de la política con la ciencia y la tecnología</h5>
                        <p>Pensar por un instante en la política como un ente no influyente en cuanto a temas de ciencia y tecnología sería una mera falacia.</p>
                        <p>En la búsqueda de antecedentes sobre los inicios del desarrollo en ciencia en la Argentina, me he topado con que  la política y la ciencia están históricamente ligadas. La primera manifestación en nuestro país del dicho desarrollo se remonta a la creación de la primera universidad en Córdoba cuyo estudios solo incluían aspectos de la ciencia en el marco de la filosofía. Sin embargo a mediados de 1799, y de la mano de Manuel Belgrano, fue creado el primer centro educativo ligado a la ciencia, “La escuela Náutica”, cuya misión principal era formar navegantes y  cartógrafos, en consecuencia se dictaban cursos de trigonometría, geometría entre tantos otros.</p>
                        <p>En la actualidad se puede observar que aquellos países más desarrollados en cuento a ciencia y tecnología son los que han destinado más fondos económicos a tal causa. Tenemos el ejemplo de Japón cuyo avance tecnológico le permite tener una alta estabilidad economía y otros beneficios. Estados Unidos, quien alberga importantes compañías como lo son Google, Silicon Valley, Apple, Microsoft entre tantas otras, que demuestra que un país que destina fondos a la tecnología y ciencia es un país atractivo para nuevos inversores. </p>
                        <p>No solo la ciencia y tecnología proporciona cambios en la estabilidad de un país sino que también produce cambios importantes en la sociedad, cambios en su ideología, en su organización, pero más aún, la tecnología es responsable de muchas transformaciones sociales en mayor medida que la ciencia moderna, lo que quiere decir que la tecnología modela sociedades. ¿Y esto a que se debe?</p>
                        <p>Es una pregunta que se responde por si sola si se toma como marco observador a la propia sociedad, en contexto en el cual se encuentra. Estamos inversos en productos tecnológicos y la sociedad demanda más y más tecnología, ya sea en ámbitos urbanos, rurales, espacios laborares o el hogar. Haciendo hincapié sobre lo que menciona el autor, se produce un efecto de experiencias personales con la tecnología,  que si bien nada tiene que ver con la ciencia, en el ámbito social no adquieren diferencias.</p>
                        <p>Cabe destacar que no todas las sociedades tienen el mismo desarrollo socio tecnológico o socio científico, esto  se debe principalmente a los problemas de inversiones, presupuestos aplicado para el desarrollo de la ciencia por parte de los gobiernos. También existen otros factores como lo son: la continuidad de los gobiernos en el poder, dado que la mayor parte de las veces no adoptan un mismo plan económico; las diferencias regionales (es claro que no todas las regiones pueden tener el mismo desarrollo científico-tecnológico porque muchas de ellas ni si quiera pueden proporcionar los elementos básicos para el desarrollo de una sociedad como lo son el agua, los alimentos, la energía).</p>
                        <p>En conclusión, la ciencia sustenta las bases de la tecnología, la primera resuelve los problemas de forma teórica mientras que la segunda los lleva a cabo, pero no siempre se debe considerar como un medio. Ciencia y tecnología es innovar y en consecuencia representa un desarrollo ideológico cultural y organizacional para un país, para una región. Para que en un país exista desarrollo en ciencia y tecnología, deben considerarse en las agendas políticas y estas proporcionar planes económicos para sustentar y proveer de los recursos, con lo que ciencia y política pueden considerarse como dos caras de una misma moneda.</p>
                    </div>
                </div>    

                <div className="row">
                    <div className="col-md-12">
                        <h4>La relación entre el desarrollo de los países, con la educación la ciencia y la tecnología</h4>
                        <p>Las características propias de cada sociedad son consecuencias directas del desarrollo científico tecnológico que estas posean pero no debe considerarse a la ciencia y tecnología como procesos autónomos que siguen una lógica de desarrollo sino como un proceso social.</p>
                        <p>La ciencia y la tecnología son indispensables para el desarrollo social y económico de un país. Sus aportes son fundamentales para mejorar la salud, aumentar la calidad y duración de la vida y optimizar el trabajo pero existen  brechas que se generan en las sociedades las cuales se hacen más profundas y difíciles de cerrar a medida que surgen los avances tecnológicos. Esto  provoca una notable diferenciación en cuanto al progreso de una nación, factor que se atribuye principalmente a la educación, la investigación científica y el desarrollo tecnológico que sirven de bases para catalogar a un país como desarrollado, en vías de desarrollo o subdesarrollados. Una característica importante a destacar es que países con más desarrollo buscan agruparse tornándose dependientes unos de otros. </p>
                        <p>Se sabe que a mayor conocimiento se espera una mejor calidad de vida y el estado debería garantizar que las sociedades puedan adquirir los recursos y educación necesarias para obtener un mano de obra calificada y así integrarse a los sectores productivos, pues a medida que la ciencia y la tecnología evolucionan nos volvemos más analfabetos y si aún no lo somos, en un futuro inmediato lo podríamos ser. Este fenómeno se debe a que si bien muchas personas saben leer y escribir sin dificultades, que tienen sus estudios primarios y secundarios completos, hoy en día pueden considerarse analfabetos tecnológicos por no estar instruidos y no contar con las habilidades necesarias para poderse desenvolver con soltura en el usos de estas herramientas modernas, recursos, etc.</p>
                        <p>En la actualidad ciencia y tecnología son sinónimos de competitividad, determinan el rumbo de una sociedad y la educación desempeña un rol importantísimo como mecanismo a la hora de formar intelectualmente y moralmente a los individuos.</p>
                    </div>
                </div>       
                
                <div className="row">
                    <div className="col-md-12">
                        <h4>¿Cuál es la situación en la Argentina?</h4>
                        <p>Ppara que un país encuadre dentro del marco mundial de las naciones no es condición suficiente contar con una organización política y territorial sino que existen otros agentes que determinan si se está o no dentro 
                            del sistema, unos de estos factores determinantes lo son la educación y la actividad científico tecnológico formarían parte de estos otros agentes. Desde mi opinión concuerdo con las perspectivas en las cuales hace hincapié el autor.  Estoy convencido que nuestro país ha sido fuente de recursos humanos y mentes brillantes que han sabido estar a la altura de los desafíos en cuanto a desarrollo científico, sin embargo la inestabilidad económica del país han generado pérdidas en la calidad educativa principalmente en el sector público, diferencias regionales (no a todas las regiones de nuestro país se le da la misma atención de recursos económicos, Chaco, Misiones, Jujuy, etc. comparadas con Buenos Aires, Córdoba, San Luis, etc.); presupuestos invertidos que restan prioridad a las actividades científico tecnológicas provocan un envejecimiento en la comunidad investigadora. Lo cierto es que muchos de estos investigadores al no contar con los recursos necesarios, al no contar con el apoyo del estado terminan siendo emigrantes hacia otros países.</p>
                        <p>La falta de continuidad de los gobierno en sus planes políticos, las medidas poco ortodoxas adoptadas en el 2019 y brutales ajustes de fondos destinados al sector educativo, que por cierto ya eran insuficientes; hacen que en la Argentina se acrecienten los índices de pobreza, deserción y fracaso escolar en edades cada vez más tempranas. Como  consecuencia nos encontramos ante la escasez del factor humano especializado. </p>    
                        <p>Se requieren de políticas que minimicen el riesgo país, continuidad de dichas políticas que acompañen estabilidad económica para que los inversores privados sean tentados a invertir en nuestro país. </p>
                        <p>Está claro que sin inversión en ciencia y tecnología la Argentina queda lejos de ser un país competitivo y fuera del foco de los inversores de países desarrollados.</p>
                        <p>Ciencia, tecnología y sociedad se concentra en tres grupos: investigación, política de ciencia y tecnología, educación.  Haciendo énfasis a este último elemento que conforma la cadena de Ciencia tecnología y sociedad,  como bien lo describió Platón en su alegoría de las cavernas,  la educación nos brinda la posibilidad de poder descubrir nuevas fronteras, liberar nuestras mentes, poder ser críticos, poder decidir a conciencia, poder diferenciarnos del resto, de poder hacer cosas innovadoras, de evolucionar, etc. En conclusión el saber nos brinda el poder.</p>
                        <p>Nuestro país transita por terrenos dificultosos, incremento de la pobreza, falta de empleos, falta de inversionistas; sin embargo y a pesar de todo es fuente de recursos científicos con renombre internacional como lo fue la mujer Argentina  "Andrea Gamarnik", quien recibió el premio internacional por sus estudios sobre el virus del dengue1. Para que sigan surgiendo mentes brillantes en necesario que se tomen planes políticos en los cuales se priorice la inversión y destino de fondos para el desarrollo de la ciencia y la tecnología, de esta manera se podremos ser competitivos con el resto del mundo.</p>
                    </div>
                </div>              

                {/* <div className="row">    
                    <div className="col-md-12 ">
                        <img src={require("../assets/images/muroeducacion.jpg")} />
                    </div>
                </div> */}

        </div>
      </div>  
    );
  }
}
 
export default DigitalRevolution;