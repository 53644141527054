import React, { Component } from "react";
import Carousel from "react-bootstrap/Carousel"; 

class Slider extends Component {

  render() {
    return (
    <div>
      <Carousel>
      {/* slider_realidad_aumentada.jpg */}
        <Carousel.Item>
            <img
              className="d-block slider-properties"
              src={require("../../assets/images/slider3.jpg")}
              alt="First slide"
            />
            <Carousel.Caption>
              <h3>Tecnologías de la Información y la Conectividad</h3>
              <p>Blog de Educación, ciencia y tecnología</p>
            </Carousel.Caption>
        </Carousel.Item>

        {/* <Carousel.Item>
          <img
            className="d-block w-100"
            src={require("../../assets/images/slider3.jpg")}
            alt="Third slide"
          />
          <Carousel.Caption>
            <h3>Tecnología</h3>
            <p>La evolucion de la sociedad y su contexto tecnológico.</p>
          </Carousel.Caption>
        </Carousel.Item> */}

        {/* <Carousel.Item>
          <img
            className="d-block w-100"
            src={require("../../assets/images/slider_realidad_aumentada.jpg")}
            alt="Third slide"
          />

          <Carousel.Caption>
            <h3>Third slide label</h3>
            <p>Praesent commodo cursus magna, vel scelerisque nisl consectetur.</p>
          </Carousel.Caption>
        </Carousel.Item> */}

      </Carousel>
    </div>
    );
  }
}
 
export default Slider;