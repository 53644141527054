import React, { Component } from "react";

import Slider from '../components/slider/Slider';
import SectionInfotmation from '../components/sections/SectionInformation';
import SectionReflextionTechnology from '../components/sections/SectionReflextionTechnology';
import SectionPortfolio from '../components/sections/SectionPortfolio';
import SectionReflectionEducation from '../components/sections/SectionReflectionEducation';
import SectionLinks from '../components/sections/SectionLinks';
import SectionAbout from '../components/sections/SectionAbout';
import Navbar from "../components/navbar/Navbar";

class Home extends Component {
  render() {
    return (
      <div>

        <Navbar />
        <Slider />
        <SectionInfotmation />
        <SectionReflextionTechnology  />
        <SectionPortfolio />
        <SectionReflectionEducation />
        <SectionLinks />
        <SectionAbout />

      </div>
    );
  }
}
 
export default Home;