import React, { Component } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";


// Importar estilos
import './assets/css/App.css';
import './assets/css/Styles.css';

// Importar componentes especificos
import Footer from './components/footer/Footer';

// Importar paginas enturadas
import Home from './pages/Home';
import DigitalRevolution from './pages/information/DigitalRevolution';
import SocialEducation from './pages/information/SocialEducation';
import TechnologicalTools from './pages/information/TechnologicalTools';

import HistoryComputers from './pages/porfolio/HistoryComputers';
import ComputersFuture from './pages/porfolio/ComputersFuture';
import Security from './pages/porfolio/Security';
import Nanotechnology from './pages/porfolio/Nanotechnology';
import Hardware from './pages/porfolio/Hardware';
import Software from './pages/porfolio/Software';

import NotFound from './pages/NotFound';


class App extends Component {
  render() {
    return (
      <Router>
        <div>

          {/* DECLARACION DE RUTAS */}
          <Switch>
            {/* <Route path="/post/:id" component={Post} /> */}
            <Route exact path="/" component={Home} />
            <Route path="/educacionsocial" component={SocialEducation} />
            <Route path="/revoluciondigital" component={DigitalRevolution} />
            <Route path="/herramientastecno" component={TechnologicalTools} />
            <Route path="/historycomputers" component={HistoryComputers} />
            <Route path="/computersfuture" component={ComputersFuture} />
            <Route path="/security" component={Security} />
            <Route path="/nanotechnology" component={Nanotechnology} />
            <Route path="/hardware" component={Hardware} />
            <Route path="/software" component={Software} />
            <Route component={NotFound} />

          </Switch>

          <Footer />

        </div>
      </Router>
    );
  }
}

export default App;
