import React, { Component } from "react";
import { Link } from "react-router-dom";

class TechnologicalTools extends Component {

  // Redirect and Scroll
  // Funcion para regresar a la pagina anterior y posicionarse
  // en la seccion de Informacion.
  handleClick(event) {
    event.preventDefault();    
    const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop) ;        
    window.history.back(scrollToRef);
  }      

  render() {
    return (
        <div className="technologicaltools">

            <Link to="/#" onClick={this.handleClick}><span className="link-regresar fa fa-arrow-circle-left"></span></Link>
            
            <div className="container">          
                <h3 className="titulo">Herramientas Tecnológicas</h3>
                <div className="row">
                    <div className="col-md-12">
                        <p>La tecnología puede ayudar a las organizaciones a aumentar la rentabilidad y finanzas de sus negocios, mejorando la eficacia de sus procesos posicionando a las organizaciones, mediana y grandes empresas, pymes, etc; en un mejor lugar en el mercado.</p>
                        <p>Muchas organizaciones invierten grandes sumas de sus recursos para el desarrollo e investigación de nuevas herramientas tecnológicas y es que su alcance en el área financiera es muy prometedor. Pero lo cierto
                            es que las herramientas tecnológicas no estan atadas solamente al ambiente financiero o empresarial, sino que tambien estan abocadas al area institucional educativo.</p>
                        <hr/>
                        <h5>Entonces, ¿qué es una herramienta tecnológica?</h5>
                        <p>Las herramientas tecnológicas son programas de computadoras y aplicaciones comunmente llamadas [software] destinadas para ser utilizadas en diversas actividades.
                        Su finalidad, hacer que las el desarrollo de nuestras actividades en el área informática sea mas sensillo y fácil de realizar permitiendo eficientemente el intercambio de información y conocimiento dentro y fuera de las organizaciones.</p>
                        
                        <div className="itemlist">
                            <h4>Clasificación de las herramientas tecnológicas</h4>
                            <ul>
                                <li>Procesadores de texto</li>
                                <li>Presentaciones multimedias</li>
                                <li>Diseño de fotos</li>
                                <li>Diseño de folletos</li>
                                <li>Hoja de cálculo</li>
                            </ul>
                        </div>

                        <h4>Herramientas Tecnológicas para el análisis de grandes volumenes de datos</h4>
                        <p>Para mejorar la rentabilidad de los negocios, ventas de productos y servicios, los encargados de conducir las organizaciones deben tomar las mejores decisiones dentro de un avanico de posibilidades y de análisis de infomación.</p>
                        <p>Existen herramientas no convencionales para poder suplir estas dificultadas y facilitar el analisis de datos.</p>
                        
                        <h5>Big Data</h5>
                        <p>Cuando hablamos de Big Data nos referimos a conjuntos de datos o combinaciones de conjuntos de datos cuyo tamaño (volumen), complejidad (variabilidad) y velocidad de crecimiento (velocidad) dificultan su captura, gestión, procesamiento o análisis mediante tecnologías y herramientas convencionales, tales como bases de datos relacionales y estadísticas convencionales o paquetes de visualización, dentro del tiempo necesario para que sean útiles.</p>
                        <p>En la actualidad estamos más conectados con personas y dispositivos, tenemos acceso a más redes y servicios, y sin duda consumimos y producimos mayores cantidades de datos e información.
                            Las empresas que venden y brindan algun tipo de servicio, necesitan conocer a sus clientes/ususarios para poder ofrecer productos que se adecuen a sus perfiles.
                        </p>
                        <p>Big Data son grandes volumenes de datos. Si pensamos en la cantidad de informacióon que viaja por las redes sociales como Facebook, todos y cada uno de los mensajes generados por los usuarios de estas redes sociales proporicionan grandes volumentes de datos, terabyte de informacion a la cual se la denomina Big Data. </p>                    
                        <p>Para poder analizar esta informacción las herramientas convencionales no son suficientesmente eficientes. Para ellos es necesario contar con herramientas capaces de suplir las necesidades informáticas y aquí algunas de las más conocidas: 
                        <b>Hadoop, Pig, Hive, Cassandra, Spark, Kafka, etc</b></p>

                        <img src={require("../../assets/images/big-data.jpg")} alt="" />

                        <h5>¿Que es Hadoop?</h5>
                        <p>Hadoop es un sistema de código abierto (Open Source) que se utiliza para almacenar, procesar y analizar grandes volúmenes de datos. Sus ventajas son muchas:</p>
                        <ul>
                            <li>Aísla a los desarrolladores de todas las dificultades presentes en la programación paralela.</li>
                            <li>Cuenta con un ecosistema que sirve de gran ayuda al usuario, ya que permite distribuir el fichero en nodos, que no son otra cosa que ordenadores con commodity-hardware.</li>
                            <li>Es capaz de ejecutar procesos en paralelo en todo momento.</li>
                            <li>Dispone de módulos de control para la monitorización de los datos.</li>
                            <li>Presenta una opción que permite realizar consultas.</li>
                            <li>También potencia la aparición de distintos add- ons, que facilitan el trabajo, manipulación y seguimiento de toda la información que en él se almacena. </li>
                        </ul>
                        <p>Algunas de las catacteristicas:</p>
                        <ul>
                            <li><b>Reducción de coste: </b>Las grandes tecnologías de datos, como Hadoop y el análisis basado en la nube, aportan importantes ventajas en términos de costes cuando se trata de almacenar grandes cantidades de datos, además de identificar maneras más eficientes de hacer negocios.</li>
                            <li><b>Más rápido, mejor toma de decisiones: </b>Con la velocidad de Hadoop y la analítica en memoria, combinada con la capacidad de analizar nuevas fuentes de datos, las empresas pueden analizar la información inmediatamente y tomar decisiones basadas en lo que han aprendido.</li>
                            <li><b>Nuevos productos y servicios: </b>Con la capacidad de medir las necesidades de los clientes y la satisfacción a través de análisis viene el poder de dar a los clientes lo que quieren. Con la analítica de Big Data, más empresas están creando nuevos productos para satisfacer las necesidades de los clientes.</li>
                        </ul>
                        
                        <img src={require("../../assets/images/big-data-2.jpg")} alt="" />
                    </div>
                </div>      

                <div className="row">
                    <div className="col-md-12">
                        <h4>Herramientas Tecnológicas para la educación inclusiva</h4>
                        <p>La tecnología de la información y la comunicación (TIC) dentro de del marco de sus posibilidades 
                        nos ofrece la atención a la diversidad. Por medio de estas herramientas informáticas se puede facilitar 
                        la educación de calidad y el acceso a diversas necesidades educativas especiales.</p>
                        <p>Sabemos que los estudiantes que tienen algún tipo de discapacidad se enfrentan, en su mayoria, 
                        a la dificultad de acceder a una educación igualitaria y equitativa. Es aquí donde entra en juego el uso de nuevas 
                        formas pedagógicas para solventar estas problematicas de desigualdades a través de las llamadas herramientas tecnológicas.
                        </p>
                        <p>Ante una determinada discapacidad es preciso planear y elaborar estrategias pedagógicas para atender las necesidades educativas especiales que el alumno/a requiera para acompañarlo en su aprendizaje.</p>
                        <p>Hablar de inclusión no se refiere a integración, ya que supone pasar de un modelo individual, donde el problema o limitación está en el individuo, a un modelo social, donde los problemas y las limitaciones están en la sociedad. 
                        El objetivo de la inclusión e integración son diferentes. La inclusión es de mayor magnitud que el de la integración, en el sentido de que éste se centra en el colectivo de los individual con algún tipo de discapacidad. 
                        Por otra parte, el objetivo de la integración es mejorar y cambiar la educación especial mientras que a la inclusión se refiere,  es perseguir la transformación de la cultura, la organización educativa y la práctica escolar.</p>
                        <p>El principal objetivo que debe perseguir el desarrollo de una herramienta destinada para la inclusión de individuos en el ambito institucional educativo son:</p>
                        <ul>
                            <li>Igualdad de uso. El diseño debe ser fácil de usar y adecuado para todas las personas independientemente de sus capacidades y habilidades.</li>
                            <li>Flexibilidad. El diseño debe poder adecuarse a un amplio rango de preferencias y habilidades individuales.</li>
                            <li>Simple e intuitivo. El diseño debe ser fácil de entender independientemente de la experiencia, los conocimientos, las habilidades o el nivel de concentración del usuario.</li>
                            <li>Información fácil de percibir. El diseño debe ser capaz de intercambiar información con el usuario, independientemente de las condiciones ambientales o las capacidades sensoriales del mismo.</li>
                            <li>Tolerante a los errores. El diseño debe minimizar las acciones accidentales o fortuitas que pueden tener consecuencias fatales o no deseadas.</li>
                            <li>Escaso esfuerzo físico. El diseño debe poder usarse eficazmente y con el mínimo esfuerzo posible.</li>
                            <li>Dimensiones apropiadas. Los tamaños y espacios deben ser apropiados para el alcance, manipulación y uso por parte del usuario, independientemente de su tamaño, posición o movilidad.</li>
                        </ul>

                        <br/>

                        <img src={require("../../assets/images/sistemacomunicacionalternativa.jpg")} alt="" />
                        
                        <h2>Sistemas alternativos de comunicación</h2>
                        <p>Como se muestra en la imagen, un claro ejemplo del uso de nuevas herramientas tecnológicas para el desarrollo del aprendizaje.</p>
                        <p>Esta herramienta teccnológica usa dos sistemas alternativos de comunicación llamados aumentativos y alternativos. 
                        Su objetivo es mejorar la comunicación de las personas con dificultades en el habla, aumentando nuevas vías de comunicación, 
                        fomentando la autonomía de estas personas.</p>
                        <ul>
                            <li><b>Sistemas Aumentativos</b>, complementa el lenguaje oral cuando por sí sólo no es suficiente para una buena comunicación con el entorno.</li>
                            <li><b>Sistemas Alternativos</b>, sustituyen al lenguaje oral por ausencia del lenguaje o por ser incomprensible para los demás.</li>
                        </ul>

                        <p><u>Clasificación de los sistemas alternativos y aumentativos de comunicación</u></p>
                        <ul>
                            <li><b>Sin ayuda</b>, no necesitan ningún instrumento o ayuda técnica. El usuario configura los mensajes con alguna parte de su cuerpo. Ejem: lenguaje de signos.</li>
                            <li><b>Con ayuda</b>, requiere recursos externos para la comunicación. Hay ayudas básicas o no electrónicas, electrónicas sencillas que facilita la autonomía.</li>
                        </ul>              
                    </div>
                </div>  
            </div>
       </div>  
    );
  }
}
 
export default TechnologicalTools;