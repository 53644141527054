import React, { Component } from "react";
import { Link } from "react-router-dom";
import { animateScroll as scroll} from "react-scroll";

import { scroller } from "react-scroll";
import { Element } from "react-scroll";

class Hardware extends Component {

  constructor(props){
    super(props);
    scroll.scrollToTop(); // posicionarme al inicio de la pagina.
  }
  
  handleClick(event) { // funcion para regresar a la pagina anterior.
    event.preventDefault();    
    const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop) ;        
    window.history.back(scrollToRef);
  }  
  
  ActionIrToSection(parameter, event) { // funcion para posicionarme en una determinada seccion de la misma pagina.
    event.preventDefault();    
    scroller.scrollTo(parameter);
  } 

  render() {
    return (
      <div className="hardware">

        <Element name="section-arriba">
        {/* posicionamiento al comienzo de la pagina */}
        </Element>

        {/* Botones de direccionamiento */}
        <Link to="/#" onClick={this.ActionIrToSection.bind(this, "section-arriba")}><span className="link-arriba fa fa-arrow-circle-up"></span></Link>
        <Link to="/#" onClick={this.handleClick}><span className="link-regresar fa fa-arrow-circle-left"></span></Link>
        <Link to="/#" onClick={this.ActionIrToSection.bind(this, "section-abajo")}><span className="link-abajo fa fa-arrow-circle-down"></span></Link>
       
        <div className="container">
                   
          {/* <h1>Hardware/Arquitectura</h1> */}
          <h1>Hardware</h1>
          <h2>Arquitectura de computadoras.</h2>
          <br/>
          {/* link para ir directamente a secciones de la misma pagina */}
          <Link to="/#" onClick={this.ActionIrToSection.bind(this, "section-cpu")} className="bto-personalizdo">
          &#60;&#60;Ir a Unidad Central de proceso (CPU).&#62;&#62;&nbsp; 
          </Link>
          <Link to="/#" onClick={this.ActionIrToSection.bind(this, "section-memorias")} className="bto-personalizdo">
          &#60;&#60;Ir a Memoria.&#62;&#62;&nbsp; 
          </Link>
          <Link to="/#" onClick={this.ActionIrToSection.bind(this, "section-entradaSalida")} className="bto-personalizdo">
          &#60;&#60;Ir a E/S.&#62;&#62;&nbsp; 
          </Link>
          <Link to="/#" onClick={this.ActionIrToSection.bind(this, "section-buses")} className="bto-personalizdo">
          &#60;&#60;Ir a Canales de interconexión.&#62;&#62;&nbsp; 
          </Link>          
          <p></p>
          
          <p>
            A lo largo de la historia la información ha sido un factor clave para 
            la evolución de la humanidad y su tratamiento el objeto de estudio de 
            muchos especialistas en la materia.
          </p>

          <p>
            El creciente volumen de información obliga la búsqueda constante de nuevas 
            alternativas de almacenamiento, procesamiento y transmisión de datos. 
            La informática surge de la ciencia para el estudio y desarrollo de métodos 
            y dispositivos eléctricos destinados al tratamiento de datos y la computadora es un elemento 
            clave para realizar estas tareas de forma automática.
          </p>

          <p>
            Una computadora es un dispositivo que procesa y convierte datos en información 
            útil para las personas. El tratamiento de la información transita por 3 etapas fundamentales:
          </p>
          <img src={require("../../assets/images/Hardware/funcion_computadora.png")} alt="" />

          <p>A su vez una computadora se encuentra dividida en dos partes:</p>
          <ul>
            <li>Una parte lógica la cual, mediante instrucciones programadas, le da un propósito al tratamiento de los datos.</li>
            <li>Otra física encargada de ejecutar instrucciones máquina, almacenar y transmitir estos datos.</li>
          </ul>
          <p>Esta última es la que se conoce con el nombre de Hardware y que a continuación trataremos com más profundidad.</p>

          <p>Entonces.. <strong>¿Qué es Hardware?</strong></p>
          <p>
            Los componentes físicos y tangibles que forman parte de un sistema informático
            reciben el nombre de Hardware. Este termino se utiliza para aludir a
            todas las partes mecánicas, electronicas y cualquier elemento fisico 
            que constituya para el funcionamiento de una computadora. Pero este 
            termino es extensible a cualquier dispisitivo electronicos que a 
            pesar de no estar conectados directamente a una computadora, tienen 
            caracteristicas espefificas que hacen a un sistema informático.
            Ejemplo de estos dispisitivo pueden ser un router, placas externas
            de red, en definitiva cualquier dispositivo inalámbrico.
          </p>
          <p>
            El Hardware evoluciona a pasos agigantados y a menudo los 
            equipos que se compran como de última tecnología marcan 
            una línea de tiempo de caducidad muy corta. Esto se debe
            principalmente a el gran volúmen de información que se
            esta manejando en la actualidad y sobre todo su procesamiento.
          </p>
          <p>
            Hablar de Hardware nos obliga a sumergirnos en el mundo de la
            estructura funcional de una computadora.
            Con la intencion de poder simplificar el estudio del funcionamientode una computadora 
            haremos mensión de algundos de sus componentes mas importantes.
          </p>
          <p>
            Cada uno de los dispositivos de hardware a través de los cuales la computadora se comunica con el exterior, 
            reciben el nombre de <b>unidades periféricas</b>.       
          </p>
          <p>
            Existen una gran variedad de dispositivos perifericos que permiten la interacción y la transmisión 
            de información entre el exterior y la computadora. Entre ellos podríamos mencionar a los dispotivos 
            de entrada como el:
          </p>
          <ul>
            <li>Teclado</li>
            <li>Escáner</li>
            <li>Mouse</li>
            <li>Lector de códigos de barras</li>
          </ul>
          <p>Y los dispositivos de salida como:</p>
          <ul>
            <li>Monitor</li>
            <li>Impresora</li>
            <li>Altavoces (parlantes)</li>
            <li>Auriculares</li>
            <li>Plotter</li>
          </ul>

          <img className="img-arq-vn" src={require("../../assets/images/Hardware/Arq_Von_Neumann.png")} alt="" />
          <img className="img-arq-vn" src={require("../../assets/images/Hardware/perifericos.png")} alt="" />
          <p>
            Todos y cada uno de estos elementos se encuentra interconectados a una placa base principal 
            denominada <strong>motherboard</strong>.
          </p>
          <p>
            En el motherboard se encuentra los bus o canales de interconexión que permiten que viajen 
            las señales desde el micro-procesador a los demas dispositivos y viceversa. 
          </p>

          <p>
            El funcionamiento de una computadora se basa en la captura de datos, en la ejecución y 
            procesamiento de estos datos a través de una unidad central de proceso y finalmente
            devolver como resultado información útil.            
          </p>

          <p>
            Para poder desarrollar el procesamiento de los datos de entrada 
            las computadoras estan dotadas de una unidad central de proceso (CPU) 
            encargada de administrar todo el sistema.
          </p> 

          <Element name="section-cpu">
          <h3>Unidad Central de Proceso (<b>CPU</b>)</h3>
          </Element>

          <p>
            La (CPU) consta de elementos fundamentales que  son  la <strong>unidad control (UC)</strong>, 
            <strong>la unidad aritmética y lógica (ALU)</strong> y los <strong>registros (RI)</strong> que hacen de memoria interna 
            para guardar temporalmente los cálculos desarrollados dentro de la CPU 
            comunmente llamada <strong>"el procesador"</strong>.
          </p>

          <img src={require("../../assets/images/Hardware/procesador-1.jpg")} alt="" />

          <p>Dentro del procesador podremos encontrar los siguientes componentes...</p>

          <img className="img-procesador" src={require("../../assets/images/Hardware/procesador-2.png")} alt="" />          


          <div className="txtdeco1">
          Unidad de control (UC):
          </div>          
          <p> 
            Regula la ejecución de cada instruccion que se realiza en una computadora 
            controlando el acceso a la memoria primcipal, la sincronizacion de las operaciones
            de entrada y salida (señales que son enviadas y recividas desde los periféricos).
          </p>
          <p>La unidad de control realiza las siguientes acciones...</p>
          <ul>
                <li>Lee de memoria las instrucciones máquina que forman el programa.</li>
                <li>Interpreta cada instrucción leída.</li>
                <li>Lee los datos de memoria referenciados por cada instrucción.</li>
                <li>Ejecuta cada instrucción.</li>
                <li>Almacena el resultado de cada instrucción.</li>
          </ul>

          <div className="txtdeco1">
          Unidad aritmética-lógica (<b>ALU</b>):
          </div>
          <p>
            Realiza dos clases de funciones; las operaciones (suma, resta, multiplicaciones, división y funciones avanzadas) 
            y las funciones de tipo lógico, (como las comparaciones de mayor que (&#62;), menor que (&#60;), igual que (=), 
            o los operadores lógicos  (Y), (O), y (NOT) del álgebra de Boole.
          </p>

          <p>
            Entre los procesadores, la principal diferencia es básicamente la tecnología que cada uno tiene, por eso es imposible decir que uno sea mejor que el otro. Lo que hay son procesadores con tecnologías e indicaciones distintas para cada perfil de usuario.
          </p>
          <p className="box">
            En la actualidad podemos encontrar en el mercado varios tipos y modelos de procesadores, entre ellos  Intel y AMD. En cuanto a los procesadores Intel, algunas de sus características se detallan a  continuación:
          </p>


          <p><strong className="txtdeco2">Intel Core2 Duo: </strong>
          Los Intel Core2 Duo son las tecnología más antigua de todos los modelos de los que vamos a hablar y tienen dos 
          núcleos (dual-core), son recomendados los procesadores antiguos diseñados para usuarios avanzados que pretenden 
          trabajar con varias aplicaciones al mismo tiempo o con programas pesados.  La nueva gama de Intel son los Core i, 
          ya que han decidido darle un importante giro a su estructura de marcas de procesadores. Hasta ahora teníamos los 
          Core Duo, Core 2 Duo, Core 2 Quad, pero ahora ya tenemos la serie Core i3, Core i5 y Core i7.
          </p>

          <p><strong className="txtdeco2">Intel Core3: </strong>
            Los Core i3, para procesadores de gama básica o de entrada; Core i5 para requerimientos intermedios; y el Core i7 
            para usuarios de alto desempeño. Estos modelos han empezado a aparecer en los desarrollos actuales de Intel como 
            los nombre clave Lynnfield y Clarkfield y Bloomfield.  
          </p>
          <p>
            Intel distribuye su nueva gama de microprocesadores, los micros i7, i5 e i3, que tienen como nombre Lynnfield 
            (Core i5 y Core i7 de gama baja) y Clarkdale (Core i5 con gráficas integradas y los Core i3).
          </p>
          <p>
            Los microprocesadores Bloomfield quedan reservados a los más potentes Core i7 que no son caros y pocos accesibles para un uso doméstico.  
          </p>
          <p>
            Los <strong>Core i3</strong> son unos microprocesadores de doble núcleo y fabricados en 32nm, 4MB de Caché L3 y lo más novedoso 
            y distintivo es que <strong>incorporan en el propio procesador, la tarjeta gráfica</strong>.  Los procesadores Intel Core i3 están 
            diseñados para ofrecer alto rendimiento en la ejecución de videos de alta definición y tareas con gráficos 3D.            
          </p>
          <p>
            El procesador Core i3 es una opción inteligente para el hogar y la oficina, también cuenta con la Tecnología Intel® Hyper-Threading, 
            que permite que cada núcleo de su procesador trabaje en dos tareas al mismo tiempo, suministrando el desempeño que necesita para hacer 
            tares múltiples de manera inteligente.
          </p>

          <p><strong className="txtdeco2">Intel Core i5: </strong>
            Los Core i5 tienen dos versiones a la venta, la Clarkdale, que al igual que los Core i3 llevan la gráfica integrada y están fabricados en 32nm, 
            y luego los englobados en Lynnfield. Los Core i5 Clarkdale tiene cuatro núcleos. Si hablamos sobre el rendimientos que generan los Core i3 y los 
            Core i5 desarrollan 4 procesos simultáneos, 2 por cada núcleo y 1 por cada núcleo respectivamente.
          </p>
          <p>
            Si pasamos a hablar de los Core i5 Lynnfield, nos encontramos con microprocesadores de 4 núcleos que en este caso están fabricados en 45nm. Son de 
            mayor tamaño y consumen más energía que sus hermanos pequeños. Estos procesadores están orientados a ordenadores de gama alta y dan mejor rendimiento 
            gráfico y multimedia que los Clarkdale a pesar de que a partir de estos modelos ya no se incluye la gráfica en el procesador.
          </p>

          <img src={require("../../assets/images/Hardware/procesador-3.jpg")} alt="" />

          <p><strong className="txtdeco2">Intel Core i7: </strong>
            Por último, los Core i7 también tienen una serie de micros dentro de los Lynnfield y que al igual que todos estos, son acoplados a la placa base a través 
            del Socket 1156 y fabricados en 45nm. Lo mejor de los Core i7 es que además de tener 4 núcleos, llevan las dos tecnologías punteras de Intel, el <strong>Turbo Boost</strong> 
            y el <strong>Hyperthreading</strong>. Esto hace que su velocidad de procesamiento sea muy alta y que en su caso, con 4 núcleos, genere nada más y nada menos que 8 procesos simultáneos.
          </p>
          <p>
            Los microprocesadores Lynnfield (incluimos el Core i5 por supuesto) tienen una memoria Caché L3 de 8MB, el doble que los Clarkdale.  Ahora, los Core i7 Bloomfield tienen las mismas 
            características que el resto de los Core i7, pero el Socket de conexión es el modelo 1366 (posiblemente la misma que los rumoreados Core i9).
          </p>


          <p><strong className="txtdeco2">¿Qué es Turbo Boot?</strong></p>
          <p>
            Turbo Boost: Turbo es automático. Básicamente, el procesador tendrá tres estados de funcionamiento normal. El más bajo es un estado de inactividad / ahorro de energía. Luego está la 
            velocidad normal, seguida por la velocidad Turbo. El modo en el que se ejecuta la CPU depende de la carga que está experimentando.
          </p>
          <p>
            ¿Puede Turbo correr indefinidamente? Se puede, dependiendo  si la CPU se mantiene lo suficientemente fría, entonces puede seguir funcionando. De lo contrario, bajará al modo de funcionamiento sin turbo.
          </p>
          <p><strong className="txtdeco2">¿Qué es Hyperthreading?</strong></p>
          <p>
            Hyperthreading: es una especie de núcleo extra falso por núcleo, por lo que por ejemplo Pentium 4 con Hyperthreading son vistos por Windows como un núcleo dual. Un Core i3 con 
            Hyperthreading es visto por Windows como un núcleo cuádruple, porque es un dual (dos núcleos) y cada núcleo tiene Hyperthreading.
          </p>
          <p>Tabla comparativa entre modelos de procesadores:</p>
          <img src={require("../../assets/images/Hardware/procesador-4.png")} alt="" />


          <Element name="section-memorias">
          <h3>Memoria Principal y Secundarias (<b>MP/MS</b>)</h3>
          </Element>

          <p>
            La memoria principal tiene por objeto guardar información que es accesible a la CPU. La CPU puede leer y/o escribir 
            datos en las diferentes posiciones de memoria que componen la memoria principal. La memoria principal tiene menor 
            capacidad que la memoria secundaria (que virtualmente es ilimitada), pero es mucho más rápida.             
          </p>
          <p>Actualmente la memoria principal se implementa mediante circuitos integrados.</p>
          <p>
            La memoria principal de los sistemas informáticos suele estar formada por dos áreas diferenciadas:
          </p>
          <p>
            <strong>Memoria RAM (Random Access Memory)</strong>: Memoria de acceso aleatorio 
            (no tiene porqué ser utilizada de manera secuencial) que permite tanto la lectura como la escritura. 
            Habitualmente en los sistemas informáticos se trata de un medio de almacenamiento volátil, de manera que se 
            pierde su contenido al cesar la alimentación.            
          </p>
          <img src={require("../../assets/images/Hardware/memoria-1.jpg")} alt="" />
          <p><strong>Hay dos tipos de memorias RAM:</strong></p>
          <ul>
            <li>DRAM: Memoria dinámica de acceso aleatorio.</li>
            <li>SRAM: Memoria estática de acceso aleatorio (comúnmente llamada memoria caché).</li>
          </ul>
          <img src={require("../../assets/images/Hardware/memoria-2.png")} alt="" />
          
          <p>
            <strong>Memoria ROM (Read Only Memory)</strong>: Memoria de acceso aleatorio que sólo permite la lectura de los datos que almacena. 
            Se trata de un medio de almacenamiento persistente, pues no pierde su contenido cuando cesa la alimentación.
          </p>
          <img src={require("../../assets/images/Hardware/memoria-3.png")} alt="" />
          
          <p><strong>¿Cómo funcionan las memorias?</strong></p>
          <p>
            En el sistema operativo, cuando el usuario va abriendo aplicaciones, archivos, documentos, etc., todos ellos 
            se cargan también en la RAM. Algunas aplicaciones solo se cargan en parte a fin de no sobrecargar la memoria. 
            Cuando el microprocesador necesita información de la memoria envía una solicitud al controlador de memoria. 
            Este informara al microprocesador cuando la información esté disponible.            
          </p>
          <p>
            Los archivos permanecen en la RAM hasta que el usuario grabe los cambios y los cierre. Recién allí el controlador 
            de memoria borrar las celdas usadas o direcciones de memorias ocupadas por esos archivos.            
          </p>
          <p>
            Al igual que los microprocesadores son chips que alojan semiconductores que asociados logran el efecto de almacenar 
            información durante un tiempo.
          </p>
          <p>
            Con muy poco margen de error podemos ejemplificar físicamente a las memorias como una hoja cuadriculada donde 
            habrá filas y columnas.
          </p>
          <p>
            Uno de los métodos utilizados consiste en que la memoria principal almacene un número fijo de bits en cada 
            posición, para nuestro ejemplo esta posición sería una como la celda de una hoja cuadriculada. 
            Estos grupo de bits se tratan como una sola entidad la cual se denomina <strong>palabra de memoria</strong>. 
            Los primeros micro-procesadores tenian un ancho de 4 bits de palabla. A ésta agrupación se la 
            llamó nibble. En la mayoría de los casos el bus de datos es el que define el tamaño de la palabra de trabajo, 
            en la actualidad podemos contar con palabras de 8, 32 y 64 bits.            
          </p>
          <img src={require("../../assets/images/Hardware/memoria-4.png")} alt="" />


          <Element name="section-entradaSalida">
          <h3>Unidades periféricas (<b>Entrada/Salida</b>):</h3>
          </Element>

          <p>
            Para que el microprocesador sea útil debe poder comunicarse con  el mundo exterior; y 
            con frecuencia esto es posible vía teclado (keyboard) en el caso de entrada de información, 
            y para salida, el monitor y/o impresora. Los datos digitales de entrada o salida son 
            transferidos vía posts (puerto) de datos, los que básicamente son registros enganchados 
            conectados al bus de datos y seleccionados por la CPU. En algunos casos la señal es convertida 
            en una señal analógica antes de salir al exterior.            
          </p>
          <p>
            Aparte de las líneas de datos, el puerto de salida tendrá líneas de control. Una de éstas, es de salida 
            de la computadora para indicarle al dispositivo externo que está listo para transferir datos vía el puerto. 
            La segunda línea es de entrada a la computadora, y es usado por el dispositivo externo tanto para indicar que 
            el periférico está listo para aceptar datos o que tiene datos disponibles en las líneas del puerto; para que 
            la computadora los lea. Estas señales pueden ser usadas como pedido de una acción en un sentido o una señal de 
            comprendido en otro sentido, o puede ser simplemente para indica que el dispositivo está ocupado. Las señales de 
            control son importantes, cuando el microprocesador y el despótico externo operan a diferentes velocidades.
          </p>
          <img src={require("../../assets/images/Hardware/perifericos.png")} alt="" />


          <Element name="section-buses">
          <h3>Canales de interconexión (<b>Buses</b>):</h3>
          </Element>

          <p>¿Qué son los Buses?</p>
          <p>
            Un bus se puede definir como una línea de interconexión portadora de información, 
            constituida por varios hilos conductores (en sentido físico) o varios canales 
            (en sentido de la lógica), por cada una de las cuales se transporta un bit de 
            información. El número de líneas que forman los buses (ancho del bus) es fundamental. 
            Si un bus está compuesto por 16 líneas, podrá enviar 16 bits al mismo tiempo. Los buses 
            puede verse facílmente como las lineas que recorren un circuito impreso.
          </p>

          <img src={require("../../assets/images/Hardware/circuito-impreso.jpg")} alt="" />

          <p>
            Es decir, los distintos subsistemas de la computadora  intercambian datos gracias a 
            los buses. En lo que respecta al la unidad central de proceso, a la memoria y a los diferentes 
            dispositivos contextados a la placa madre, en su interior contiene tres tipos de buses encargados
            de distribuir y controlar la información que viaja de un lugar a otro. Estos buses reciben el nombre de 
            <strong> (bus de control, bus de datos y bus de direcciones)</strong>.
            Pero tambien existe una diversa variedad de buses que cumplen con funciones determinadas, por 
            ejmeplo los conectores y que veremos con más produndidad a continuación.
          </p>
          <p>
            Podemos clasificar a los buses, según el criterio de su situación física:
          </p>
          <ul>
            <li>Buses internos</li>
            <li>Buses Externos</li>
          </ul>
          <p><strong className="txtdeco2">Bus Interno</strong></p>
          <p>
            Comunmente llamado Bus de Sistema, es el encargado de mover los datos entre los componentes internos 
            del microprocesador, la memoria y los dispositivos de Entrada/Salida.
          </p>
          <p>
            Todas las partes de la unidad central de proceso están unidas mediante diversas líneas 
            eléctricas. El conjunto de estas líneas se denominan bus interno y es el encargado de transportar 
            datos y de comunicar a la unidad central con la memoria y los dispositivos de entrada y salida.
            <strong> Por este bus interno circulan los datos (bus de datos), las señales de control (bus de control) o las direcciones de memoria (bus de direcciones)</strong>. 
            Por ejemplo, cuando se habla de un microprocesador de 32 bits, se está refiriendo al número 
            de bits que podrán circular por el bus interno de forma paralela.            
          </p>
          <p>
            El bus interno puede compararse a los vasos sanguíneos del cuerpo humano por donde se transporta el oxígeno. 
            Así, por las diferentes líneas fluye la información, llegando o abandonando los registros de las memorias. 
            También podemos compararlos con las calles que recorren una ciudad donde las calles corresponden a los bus del sistema, los edificios a los diferentes dispositivos 
            internos de la computadora y los vehiculos a los datos que viajan desde la unidad de control a la memoria y a los dispositivos de E/S.            
          </p>

          <img src={require("../../assets/images/Hardware/buses-ciudad.png")} alt="" />



          <p><strong className="txtdeco2">Bus de datos</strong></p>
          <p>
            Como su nombre indica transporta datos. Estos datos pueden ser la información que se está 
            procesando o las instrucciones del programa que se ejecuta. Hay que recordar que en la arquitectura 
            Von Neumann el programa está guardado e wn el interior del sistema informático codificado como información.
          </p>
          <p>
            El ancho en bits del bus de datos define el tamaño de la palabra del sistema informático, habitualmente 
            es 8bits, 16bits, 32bits o 64bits.            
          </p>

          <p><strong className="txtdeco2">Bus de direcciones</strong></p>
          <p>
            El bus de direcciones se utiliza para indicar el origen y/o el destino de los datos. En el bus de direcciones 
            se indica la posición de memoria a la  que se está accediendo en cada momento. Puede tratarse de una dirección 
            de la memoria principal o puede tratarse de una dirección de memoria en la que está mapeado un periférico.            
          </p>
          <p>
            El ancho en bits del bus de direcciones determina el tamaño del espacio de memoria direccionable.            
          </p>

          <p><strong className="txtdeco2">Bus control</strong></p>
          <p>
            El bus de control proporciona señales para coordinar las diferentes 
            tareas que se realizan en el sistema informático. Transporta los comandos de la CPU y 
            devuelve las señales de estado de los dispositivos.
          </p>

          <p><strong className="txtdeco2">Bus Externo</strong></p>
          <p>
            El bus externo o bus de expansión, se compone de las vías electrónicas que conectan los 
            diferentes dispositivos externos, como la impresora, etc, a la computadora. Los conectores 
            también forman parte dentro del grupo de buses externos pues son a través de ellos que se 
            consigue la interconexión entre la placa madre y los distintos perifericos.
          </p>

          <img src={require("../../assets/images/Hardware/busexterno1.png")} alt="" />
          <img src={require("../../assets/images/Hardware/conextoresexternos.jpg")} alt="" />
          
          {/* <p className="advertencia">.... MEJORAR ESTA DEFINICION...</p> */}

          <Link to={"/computersfuture"} className="portfolio-text">* Leer más sobre computadoras del futuro...</Link>

        </div>
        
        <Element name="section-abajo">
            {/* posicionamiento al final de la pagina */}
        </Element>
      </div>
    );
  }
}
 
export default Hardware;