import React, { Component } from "react";
import { Link } from "react-router-dom";
import { animateScroll as scroll} from "react-scroll";

import { scroller } from "react-scroll";
import { Element } from "react-scroll";

class Nanotechnology extends Component {

  constructor(props){
    super(props);
    scroll.scrollToTop(); // posicionarme al inicio de la pagina.
  }
  
  handleClick(event) { // funcion para regresar a la pagina anterior.
    event.preventDefault();    
    const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop) ;        
    window.history.back(scrollToRef);
  }  
  
  ActionIrToSection(parameter, event) { // funcion para posicionarme en una determinada seccion de la misma pagina.
    event.preventDefault();    
    scroller.scrollTo(parameter);
  } 
    
  render() {
    return (
      <div className="nanotechnology">

        <Element name="section-arriba">
        {/* posicionamiento al comienzo de la pagina */}
        </Element>

        {/* Botones de direccionamiento */}
        <Link to="/#" onClick={this.ActionIrToSection.bind(this, "section-arriba")}><span className="link-arriba fa fa-arrow-circle-up"></span></Link>
        <Link to="/#" onClick={this.handleClick}><span className="link-regresar fa fa-arrow-circle-left"></span></Link>
        <Link to="/#" onClick={this.ActionIrToSection.bind(this, "section-abajo")}><span className="link-abajo fa fa-arrow-circle-down"></span></Link>
       

        <div className="container">
          <h1>Nanotecnología</h1>
          <br/>
          <h2>Inmersos en un mundo muy pequeño pero de grandes expectativas….</h2>
          <br/>

          <p>
            El ser humano se ha ido adaptando al medio ambiente y acompañando
            a esta adaptación la evolución tecnologíca fué quien expandió las 
            fronteras en los avances en cuanto a la busqueda de una mejor 
            calidad de vida.
            La nanotecnología es una tecnología emergente en la cual se 
            encuentran entrelazados la ciencia y la tecnología en virtud de aportar
            valor agregado a la creación de nuevos materiales.
          </p>

          <p>
            Antes de comenzar a definir que es la nanotecnología debemos saber que
            esta terminología está compuesta por dos términos “nano” que deriva del 
            griego cuyo significado es enano y “tecnología” que para este caso en 
            particular hace hincapié a todas aquellas tecnologías que permiten 
            controlar totalmente la materia a una escala manométrica, es decir a 
            escalas moleculares, atómicas y subatómicas.
          </p>
          <p>Entonces. <b>¿a que nos referimos cuando hablamos de escalas manométricas?</b></p>

          <p>
            Bueno un nanómetro es unas mil millonésimas de metro (1nm = 10<sup>-9</sup>m), una longitud 
            muy pequeña para la percepción humana sin ayuda de dispositivos como los microscopios 
            para observar esas dimensiones diminutas. Para tener una idea estas dimensiones 
            podríamos decir que caben 10 átomos, uno al lado de otro, en un nanómetro o que la 
            medida nanómetro equivale a 70 mil veces menos que la espesura de un cabello.
          </p>

          <img src={require("../../assets/images/portfolio/escala_nanometro.png")} alt="" />

          <p>Ahora que ya tenemos una idea de las escalas en las cuales se trabaja con la nanotecnología 
            pasemos a definir y conceptualizar que es la nanotecnología.
          </p>
          
          <br/>
          <h1>¿Qué es la nanotecnología?</h1>
          <br/>

          <p>
            Es una disciplina que permite crear nuevos materiales con diferentes propiedades 
            mediante el control de la materia en escala manométrica proporcionando una alternativa 
            para la incorporación de valor agregado a procesos y productos ya existentes, como así 
            también para la creación de nuevos materiales innovadores.
          </p>

          <p>
            La nanotecnología también tiene gran importancia en el campo de la electrónica a escala 
            manométrica debido a su relevancia en los efectos metálicos y cuánticos que estos 
            dispositivos puedan alcanzar.
          </p>

          <p>¿Qué propiedades cambia cuando hablamos de materiales a dimensiones manométricas?</p>
          <p>
            Primero que nada, los materiales cambian sus características dependiendo de su tamaño y 
            de la cantidad de materia que estos posean. Por ejemplo, pueden cambiar su color, punto 
            de fusión, densidad, conductividad de la electricidad, dependiendo de su tamaño.
          </p>
          
          <p>En síntesis, las propiedades fundamentales que cambia con el tamaño de los materiales son las:  </p>
            <ul>
              <li>Ópticas</li>
              <li>Eléctricas</li>
              <li>Magnéticas</li>
            </ul>
          <p>Estas tres propiedades son las que se tratan de manipular para la creación de nuevos materiales.</p>

          <p>¿Cómo hacen los científicos para ver en esa escala tan pequeña?</p>
          <p>Lo hacen a través de microscopios electrónicos de alta resoluciones. </p>

          <p><u>¿Aplicación de Nanotecnología?</u></p>
          <ul>
            <li>Energía y medio ambiente.</li>
            <li>Materiales especiales aplicados a la  fabricación de partes de automóviles o aviones.</li>
            <li>Materiales con memoria de formas (nanos materiales específicos que permiten volver a su forma original aplicando una fuerza externa o un haz de luz mediante un rayo láser.</li>
            <li>Cristales.</li>
            <li>Sensores de calidad de agua o de gases.</li>
            <li>Almacenamiento de información (cada vez es necesario guardar información y para ello los dispositivos de almacenamiento se reducen a escala nano).</li>
            <li>
              <b>Medicina y Salud:</b>
              <p>Delivery de medicamentos elaborados con partículas magnéticas para ser llevadas a lugares específicos del organismo humano, usando nanotecnología.</p>
              <p>Test de embarazo (la rayita de color que identifica embarazo positivo o negativo) está constituida por partículas de plata que cambia su color al tener interacción con ciertas hormonas del organismo humano.</p>
            </li>
          </ul>                              
          <img src={require("../../assets/images/portfolio/test_embarazo.jpg")} alt="" />

          <ul>
            <li>
              <b>Biosensores:</b>
              <p>Un biosensor es un dispositivo que permite evaluar interacciones biomoleculares en tiempo real. Cuenta de dos componentes:</p>
              <p>1) Un componente biológico, que utiliza biomoléculas como anticuerpos, enzimas, membranas y otro tipo de proteínas.</p>
              <p>2) Un componente de traducción o físico. Este componente de traducción permite evaluar la interacción de estas biomoléculas en una señal cuantificable.</p>
              <p>Como ejemplo de un biosensor, el glucómetro. El glucómetro es un dispositivo en el cual el paciente coloca una gota de sangre sobre una membrana que contiene un enzima para cataliza específicamente a la glucosa en sangre y esa medida de interacción entre el dispositivo físico y el componente biológico permiten obtener un nivel de glucosa en ese mismo momento.</p>
            </li>
          </ul>
          
          
          <img src={require("../../assets/images/portfolio/medicion-de-la-glucosa-en-sangre.jpg")} alt="" />

          <p>¿Qué beneficios conlleva el uso de la nanotecnología?</p>

          <p>
            Si se controla el tamaño o la forma de las nanopartículas se pueden crear 
            materiales con propiedades interesantes y cuyo comportamientos varian de 
            acuerdo a su tamaños específicos. Los usos y beneficios dependeran de creatividad
            de los científicos desarrolladores de nuevos productos.
          </p>
          <p>
            Empresas inovadoras como <b>naotek </b> 
            ponen a disposición a través de la comercialización de su cartera 
            de presentación una serie de productos elaborados a partir del uso de 
            nanotecnología.
          </p>

          <p>Algunos ejemplos de sus productos son:</p>
          <ul>
              <li>Apósito hipoalergénico anti-bacterial con iones de plata.</li>
              <li>Colchón antiolor para mascotas.</li>
              <li>
                <p>Almohadas:</p> 
                <p>
                  En su interior posee nano partículas de plata que liberan IONES DE PLATA 
                  capaces de alterar los procesos biológicos de los microorganismos 
                  evitando el desarrollo de hongos, virus y bacterias.
                </p>
              </li>
          </ul>

          <p>
            Científicos Argentino han desarrollado una tela desinfectante 
            que al entrar en contacto con virus y bacterias actua como un desinfectante.
            Esta tela podrá ser utilizada para fabricar barbijos, guantes, y cualquier 
            producto que requiera estar libre de microorganismos.
          </p>
          <img className="imgbarbijo" src={require("../../assets/images/portfolio/folleto_barbijos.jpg")} alt="" />

          <br/>
          <br/>
          <p>Link de interés:</p>
          <Link to={{ pathname: "https://www.fan.org.ar/nanotecnologia/" }} target="_blank">"Fundación Argentina de Nanotecnología"</Link>
          <br/>
          <br/>
          
          <Link to={"/computersfuture"} className="portfolio-text">* Leer más sobre computadoras del futuro...</Link>
          
          
        </div>

        <Element name="section-abajo">
            {/* posicionamiento al final de la pagina */}
        </Element>        
      </div>
    );
  }
}
 
export default Nanotechnology;