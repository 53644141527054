import React, { Component } from "react";
import { Link } from "react-router-dom";
import { animateScroll as scroll} from "react-scroll";

import { scroller } from "react-scroll";
import { Element } from "react-scroll";

import ReactPlayer from 'react-player';

class Security extends Component {

  constructor(props){
    super(props);
    scroll.scrollToTop(); // posicionarme al inicio de la pagina.
  }
  
  handleClick(event) { // funcion para regresar a la pagina anterior.
    event.preventDefault();    
    const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop) ;        
    window.history.back(scrollToRef);
  }  
  
  ActionIrToSection(parameter, event) { // funcion para posicionarme en una determinada seccion de la misma pagina.
    event.preventDefault();    
    scroller.scrollTo(parameter);
  }  
    
  render() {

    return (
      <div className="security">

        <Element name="section-arriba">
        {/* posicionamiento al comienzo de la pagina */}
        </Element>

        {/* Botones de direccionamiento */}
        <Link to="/#" onClick={this.ActionIrToSection.bind(this, "section-arriba")}><span className="link-arriba fa fa-arrow-circle-up"></span></Link>
        <Link to="/#" onClick={this.handleClick}><span className="link-regresar fa fa-arrow-circle-left"></span></Link>
        <Link to="/#" onClick={this.ActionIrToSection.bind(this, "section-abajo")}><span className="link-abajo fa fa-arrow-circle-down"></span></Link>
       

        <div className="container">
          <h1>Redes de computadoras</h1>

          {
          /*
          ************* 
          
          Introduccion 

          *************
          */
          }

          <p>
            La historia nos ha demostrado que los avances en la tecnología provocan cambios trascendentales en nuestra sociedad. En el siglo XVIII los grandes adelantos en los sistemas mecánicos acompañaron la revolución industrial, por ejemplo, la aparición de la máquina a vapor en el siglo XIX permitió un importante desarrollo en los sistemas de transporte tanto de personas como de recursos pero quizás el cambio más notable que experimento la sociedad fue y ha sido la tecnología aplicada a la comunicación en la cual la recolección, procesamiento y distribución de la información son las piezas claves en este mundo de datos que viajan de un lado a otro.
          </p>
          <p>
            Pensemos cómo funcionaba hace tiempo atrás el envío de una carta por medio del sistema de correo postal. Primero se escribía la carta a mano y se la colocaba en un sobre con sus respectivas estampillas. En la carta se debía especificar la dirección del remitente que envía la carta y la dirección del destino. Luego se la llevaba a un buzón donde seria recolectada por el correo.  De pendiendo del carácter de urgencia los tiempos de entrega al destinatario podían variar, pero en algunos casos la entrega tardaba meses en llegar a su destino. Es notable que los avances tecnológicos han permitido que este sistema de correo evolucione tanto en la forma de entregar el mensaje como en el medio por el cual se transporta.  Hoy en día la red computacional permite que, a través de un correo digital como Gmail, se puedan enviar mensajes de una forma mucho más ágil, rápida y segura a cualquier lugar del mundo en tan solo unos milisegundos.
          </p>
          <p>
            Como el mundo de las redes informática es basto, nos centraremos en dar algunas definiciones que nos permitan adquirir conceptos básicos sobre cómo funcionan las redes computacionales. Para ello primeramente debemos definir que es una red de computadoras.
          </p>
          <p>
            Una red de computadoras se puede definir como un medio de comunicación formado por conglomerados de dispositivos, herramientas, técnicas que permites a las personas o grupos de personas compartir información y recursos.
          </p>
          <p>
            A su vez esta red está constituida por equipos que reciben el nombre de nodos. Cada nodo puede o no estar en cercanía uno del otro lo que quiere decir que podríamos tener nodos distribuidos en un único edificio o por todo el mundo.  Las redes se categorizan de acuerdo a su ámbito de aplicación y en función de su amplitud.
          </p>
          <p>
            Cada nodo de la red de computadoras se comunica entre sí utilizando protocolos de comunicación. Se dice que las computadoras esta interconectadas si son capaces de intercambiar información con lo que cada nodo debe contar con las herramientas necesarias para tal fin.
          </p>

          {
          /* 
          **********************
          
          Clasificacion de Redes 

          **********************
          */
          }

          <h2 className="estilo-text-1">Clasificación de Redes</h2>
          
          <p>
            Las redes se clasifican según su tecnología de transmisión, modo de funcionamiento, por su extensión. Cada red sigue un mapa físico o lógico que determina como se intercambiaran los datos. Este mapa lógico o físico que representa el diseño y estructura de la red recibe el nombre de Topología de Red. Veamos más en detalle cada una de estas clasificaciones:
          </p>

          <h2 className="estilo-text-2">Clasificación por su tecnología de transmisión</h2>

          <p>
            Mencionamos que las redes de computadoras también son clasificadas por la tecnología de transmisión de información. Veamos algunas cuestiones técnicas implicadas a su diseño.
          </p>
          <p>
            En la actualidad hay dos tecnologías de transmisión de información que se destacan, ellas son los enlaces de difusión (broadcast) y los enlaces punto a punto.
          </p>

          <ul>
            <li>
              Las <b>Redes de difusión</b> poseen un solo canal de comunicación compartido por todas las 
              máquinas en donde los mensajes que envía una máquina en particular serán recibidos por todas 
              las demás. Cada mensaje contiene una dirección que especifica cual será la máquina destino. 
              En otras palabras, cuando una computadora o máquina envía un mensaje, parte de este mensaje 
              contiene la dirección a quien se dirige. Como cada mensaje es recibido por todas las máquinas 
              conectadas al canal compartido, cada una verificara la dirección contenida en el mensaje y si 
              esta dirigido a ella lo procesara, caso contrario lo desecha. 
              Un ejemplo común de un enlace de difusión sería una red inalámbrica, en donde la comunicación 
              se comparte a través de una región de cobertura que depende únicamente del canal inalámbrico y 
              de la máquina que va a transmitir.
              Como analogía de un enlace de difusión imaginemos que es lo que sucede dentro de un curso o aula 
              cuando el encargado de tomar lista dice en vos alta el nombre y apellido del alumno. El mensaje 
              lo escucharan todos los alumnos, pero solo uno contestará.
            </li>

            <li>
              Las <b>Redes punto a punto</b> consisten en una conexión entre pares de máquinas individuales, donde para 
              ir del origen al destino un mensaje debe pasar primero por uno o más dispositivos de interconexión pero 
              que estos no afectan a la topología de la red. Toda la comunicación se produce entre dos puntos, y sólo 
              entre éstos. A la transmisión punto a punto en donde sólo hay un emisor y un receptor se le conoce como 
              unidifusión (unicasting). 
              <br/>Una variante<b> son las Redes Multipunto</b> donde existe un punto central que se comunica con un 
              determinado grupos de nodos (Multicast). 
              Generalmente esto implica que la comunicación es solamente entre el punto central y los remotos, y 
              de éstos hacia el central; no existe comunicación entre los remotos. 
            </li>
          </ul>

          <img className="p2p" src={require("../../assets/images/Red/p2p_multipunto.jpg")} alt="" />

          <h2 className="estilo-text-2">Clasificación de red por modo de funcionamiento</h2>
          <ul>
            <li>
              <u><b>Cliente servidor</b></u>
              <br/>
              A nivel físico (Hardware), los servidores son máquinas (computadoras) que forman parte de una red informática. Generalmente estos equipos poseen características y recursos que les permiten poder entender y brindar determinados servicios a peticiones realizadas por otros equipos clientes. Proveen una administración centralizada y segura. Sin embargo, estos equipos que cumplen la función de servir deben contar con una aplicación (Software) capaz de poder atender las peticiones de los distintos clientes. A estos softwares también se los conocen con el nombre de servidores lógicos o servicios. Dentro de un servidor físico pueden funcionar varios servidores lógicos simultáneos. Un servidor físico o lógico debe ser capaz de ofrecer a sus clientes la posibilidad de poder compartir datos, información, recursos software o hardware.
              <br/>
              <img className="format-img-1" src={require("../../assets/images/Red/cliente-servidor.jpg")} alt="" />
            </li>
            <li>
            <u><b>De Ingual a Igual (PEER TO PEER):</b></u>
              <br/>
              En este modo de funcionamiento no hay servidores dedicados ni jerarquías entre los equipos. Todos los equipos son iguales entre si y se los conoce como pares. Cada equipo funciona a la vez como cliente y servidor. Ninguno de ellos es responsable de la red. No existe control centralizado. Esta funcionalidad resulta ser sencilla y baratas, pocos seguros y por su puesto difíciles de administras cuando aumenta el número de equipos.
            </li>
          </ul>

          <h2 className="estilo-text-2">Clasificación de red por su exteinsión</h2>
          <br/>
          <ul>
            {/* RED LAN */}
            <li>
              <p className="estilo-link">Redes de área local (LAN)</p>   
              <p>LAN es acrónimo de (Local Area Network)</p>
              <p>
                Las LAN o redes de área local son redes privadas que operan por lo generar dentro de un determinado edificio como una escuela, casa, hospital, fabrica, etc. Se utilizan para compartir recursos como archivos e impresoras, conectar computadoras personales y sobre todo para intercambiar información. Este tipo de red tiene como principal característica que su medio de transmisión es por cable, es decir que cada computadora o equipo deberá contar con su propia tarjeta de red que le permitirá enviar y recibir información a través de un cable contactado entre la tarjeta de red y un router que hará de punto de acceso y transmisión de señales. 
              </p>
              <p>
                Una de las desventajas en este tipo de red es que la transmisión de información se realiza a través de los cables interconectados por lo que deberán estar siempre en muy buen estado. Por otro lado, tiene como ventaja que la transmisión de información se realiza con mayor velocidad y puede abarcar un amplio alcance.
              </p>
              <img className="format-img-1" src={require("../../assets/images/Red/red-LAN.png")} alt="" />
              <p>
                Una alternativa a este modelo de red es la WLAN que utiliza tecnología Wifi. La señal se transmite por aire. Como ventajas es justamente no necesitar de entramados molestos de cables y conexiones física en cada equipo. Como puntos débiles, la señal puede ser interrumpida por elementos como pueden ser las paredes de los edificios. A medida que la señal debe traspasar obstáculos pierde intensidad.  
              </p>
              <img className="format-img-1" src={require("../../assets/images/Red/red-LAN-WIFI.png")} alt="" />
            </li>
            {/* RED MAN */}
            <li>
            <p>MAN es acrónimo de (Metropolitan Area Network)</p>
              <p className="estilo-link">Redes de área metropolitada (MAN)</p>
              <p>
                La red de área metropolitana permite una cobertura de mayor tamaño que una LAN, conecta ciudades y regiones metropolitanas a distancias de hasta 100 km, pero sigue siendo más pequeña que una red WAN. Las redes MAN utilizan tecnología de fibra óptica para conectar varias LAN entre sí. Esta red también tiene su versión inalámbrica llamada WMAN.
              </p>
              <img className="format-img-1" src={require("../../assets/images/Red/red-MAN.png")} alt="" />
            </li>

            {/* RED WAN */}
            <li>
              <p className="estilo-link">Redes de área amplia (WAN)</p>
              <p>WAM es acrónimo de (Wide Area Network)</p>
              <p>
                Las WAN conectan redes dentro de áreas geográficas extensas tal es así que pueden abarcar continentes enteros. Interconectan las LAN que a su vez proporcionan acceso a los equipos o servidores ubicados en otros lugares como ejemplo ciudades. Las redes que forman parte de la WAM no necesariamente están interconectadas de forma física, sino que pueden hacer uso de servicios de microondas o satélites.
              </p>
              <img className="format-img-1" src={require("../../assets/images/Red/red-WAM.png")} alt="" />
            </li>
          </ul>


          {
          /* 
          ******************

          Topología de Redes

          ****************** 
          */
          }
          <h2 className="estilo-text-1">Topología de Redes</h2>
          <p>
            La topología de red es una estructura compuesta por hardware (cableado, terminales, servidores, repetidores, etc.) y un software (acceso al medio, gestión de recursos, intercomunicación, etc.). Esta estructura determinará el diseño de la red cuyo objetivo será mejorar la confiablidad, velocidad y control del flujo de información que se transmitirá procurando evitar por un lado la congestión en el tráfico de la red y por otro la perdida de información. 
          </p>
          <p>
            Al diseñar la estructura (topología) de una red se debe tener en cuenta lo que llamaremos mapa físico y lógico de la red. Cada uno de estos mapas tendrá como finalidad expresar el aspecto que tendrá la red de datos.
          </p>

          <u>Mapa lógico de una red</u>
          <p>
            Es un esquema que tiene como finalidad mostrar la estructura de cómo está formada lógicamente el diseño de la red y los presentar los diferentes dispositivos a implementar. Ambos esquemas (físico y lógico) se diseña con la ayuda de un software como "Cisco Packet Tracer", el cual sirve de apoyo para que los arquitectos de redes puedan evaluar las mejores alternativas a la hora de crear una red informática.
          </p>
          <img className="format-img-2" src={require("../../assets/images/Red/mapa-logico.png")} alt="" />

          <u>Mapa físico de red</u>
          <p>
            Hacen referencia al aspecto que tiene el diseño del cableado que se utiliza para conectar los diferentes dispositivos de la red, incluyendo la ubicación de cada computadora y la conexión entre los equipos y el cableado.
          </p>
          <img className="format-img-2" src={require("../../assets/images/Red/mapa-fisico.png")} alt="" />

          <p><b>Las topologías física comúnmente usadas son las siguientes:</b></p>

          <ul>
            <li>
              <b>Topología de Bus:</b>
              <p>
                Topología de Bus: Utiliza solo un cable denominado backbone el cual es una línea de transmisión utilizada para transportar los datos recogidos de otras líneas más pequeñas. Unas de las particularidades de esta topología es que todas las computadoras están conectadas en esta misma línea de conexión y si alguna falla la comunicación entre las demás terminales continúa sin embargo si el que falla es el bus, la transmisión de datos de cada terminal se verá afectada por completo.
              </p>
              <img className="topologias-tipos" src={require("../../assets/images/Red/topologia-bus.gif")} alt="" />
            </li>
            <li>
              <b>Topología de anillo:</b>
              <p>
                Es este tipo de estructura cada nodo es conectado con el siguiente de manera que al llegar al último éste se conecte con el primero. Los equipos se irán conectando de manera de formar un circulo interconectados por el cable de red. Las señales pasan en una dirección y por cada uno de los equipos. Como desventaja tiene que sin alguno de los nodos falla provoca que la comunicación sea interrumpida en toda la red.
              </p>
              <img className="topologias-tipos" src={require("../../assets/images/Red/topologia-anillo.png")} alt="" />
            </li>
            <li>
              <b>Topología en estrella:</b>
              <p>
                Conecta todos los nodos con un punto central de concentración que podría ser por ejemplo un Hub o Switch. Las señales del equipo emisor pasan por el concentrador y luego va a todos los equipos. Una de las particularidades de este esquema de red es que, si se produce un fallo en algunos de los nodos, los demás no se verán perjudicados y la transmisión de información seguirá fluyendo. Sin embargo, si el fallo se produce en el concentrador entonces toda la red perderá la conectividad.
              </p>
              <img className="topologias-tipos" src={require("../../assets/images/Red/topologia-estrella.png")} alt="" />
            </li>
            <li>
              <b>Topología en estrella extendida:</b>
              <p>
                Conecta redes estrellas individuales entre sí mediante la conexión de Hubs o Switches. Su finalidad es juntamente extender el alcance y la cobertura de la red.
              </p>
            </li>
            <li>
              <b>Topología jerárquica:</b>
              <p>
                Tiene similitud con una topología de estrella extendida, pero con la salvedad que en lugar de conectar los Hubso Switches entre sí, el sistema se conecta con un equipo que controla el tráfico de toda la red.
              </p>
              <img className="topologias-tipos" src={require("../../assets/images/Red/topologia-jerarquica.png")} alt="" />
            </li>
            <li>
              <b>Topología de malla:</b>
              <p>
                Este tipo de estructura son implementadas para proporcionar la mayor protección posible para evitar interrupciones en el servicio. Son utilizadas donde es necesario mantener la conectividad y el flujo de información con tolerancia a fallos. Imaginemos una planta nuclear, debido a la complejidad e importancia de mantener los servicios de red informáticos activos ante cualquier eventualidad, esta topología resulta ser una alternativa. Como se puede visualizar en la imagen, cada nodo está provisto de su propia conexión con los demás nodos de la red. 
              </p>
              <img className="topologias-tipos" src={require("../../assets/images/Red/topologia-malla.png")} alt="" />
            </li>
            <li>
              <b>Topología en árbol:</b>
              <p>
                Es esta estructura los nodos están dispuestos de forma tal que presenten una similitud a las ramas de un árbol. Resulta ser una variante de la topología de estrella en la cual cada nodo o grupos de nodos son conectados a un Hub/Switch que a su vez están conectados a un Hub/Switch que termina haciendo de enlace troncal del cual se ramifican los demás Hub/Switch. Una de las desventajas en este tipo de arquitectura es que los datos son recibidos por todas las estaciones (nodos) sin importar a quien vayan dirigidos.
              </p>
              <img className="topologias-tipos" src={require("../../assets/images/Red/topologia-arbol.jpg")} alt="" />
            </li>
          </ul>


          {
          /* 
          ******************

          Entorno de Redes

          ****************** 
          */
          }

          <h2 className="estilo-text-1">Entorno de Red</h2>

          <p>
            Ya hemos visto conceptos básicos sobre las redes informáticas y algunas cuestiones técnicas que presentan cada uno de sus diseños. Es este apartado nos concentraremos en comprender como hacen las computadoras para poder transmitir y recibir datos a través de Internet y sobre todo como se identifican de forma única por medio de sus direcciones IP en una red de interconexión. Para ello primeramente deberemos definir algunos conceptos sobre elementos que conforman parte de la red y que permiten el control y la transmisión del tráfico de datos.
          </p>
          
          <ul>
            <li>
              <b className="title-format-1">¿Que es un HUB?</b>
              <p>
                Un Hub (en español significa concentrador), es un dispositivo diseñado para permitir la interconexión de computadoras o dispositivos a una red local. Su funcionamiento básico consiste en recibir el mensaje proveniente de un nodo determinado y replicarlo al resto de los nodos conectados a la red local. Como característica permite la conexión de varios dispositivos entre sí para que puedan comunicarse.   Un Hub funciona como un punto central de conexión desde el cual se repiten las señales a tantos nodos como se tengan conectado a él, sin embargo, este no es capaz de diferenciar si el mensaje que pasa por él va dirigido a un nodo especifico.
              </p>
              <img className="topologias-tipos" src={require("../../assets/images/Red/hub.jpg")} alt="" />
              <div align="center">
                <ReactPlayer
                  url={require('../../assets/images/Red/videos/Hub.mp4')}
                  className='react-player'
                  playing
                  width='20%'
                  height='20%'
                  controls
                  playing
                  muted
                />
              </div>                             
            </li>
            <li>
              <b className="title-format-1">¿Que es un SWITCH?</b>
              <p>
                Podríamos decir que es la versión inteligente del HUB dado que mientras este aumenta el tráfico de la red a replicar cada mensaje a todos los nodos conectados a la red local, por su parte el Switch solo envía el mensaje al nodo destino que le corresponda. En otras palabras, cuando una computadora envía un mensaje a través de un Switch este será dirigido solo a la computadora destino. El Switch identifica de forma única a cada dispositivo conectado a la red mediante su dirección MAC.
              </p>
              <img className="topologias-tipos" src={require("../../assets/images/Red/switch.jpg")} alt="" />            
            </li>
            <li>
              <b className="title-format-1">¿Que es un ROUTER?</b>
              <p>
                Lo routes se encargan de distribuir la conexión de Internet a los distintos nodos (computadoras, Smartphone, Tablet, etc) que se encuentran vinculados a una misma red local (LAN). Actúa como un intermediario entre los equipos conectados a estas redes LAN e Internet (WAN). A su vez, los Routers pueden cumplir las funciones de Hub o Switch ya que muchos de estos traen esta tecnología incorporada. De esta forma no se encuentran limitados a las redes locales, sino que dan la posibilidad a conexiones con redes externas. En otras palabras, un Router es un dispositivo encargado de redirigir los paquetes de información a otras redes para entregar el mensaje al nodo destino. Otras de las posibilidades que proporciona un router el de brindar funcionalidad de Firewall para proteger la red de peligros provenientes del exterior.
                <b> Como dato!, diferencias entre los Switchs y un Routers es los primeros crean redes mientras de los Routers conectan redes.</b>
              </p>
              <img className="topologias-tipos" src={require("../../assets/images/Red/router-modem.png")} alt="" />
            </li>
            <li>
              <b className="title-format-1">¿Que es un MODEN?</b>
              <p>
                Un modem es un dispositivo capaz de convertir las señales digitales en analógicas y viceversa. Una de sus principales características es permitir conectar dispositivos ente sí a través de la línea telefónica emisora de señales analógicas. De esta manera el modem convierte dichas señale analógicas emitiendo una nueva señal digital para poder acceder a Internet. Hoy en dia es muy común encontrar en el mercado Modem-Router que vienen con ambas tecnológicas y funcionalidades incluidas en un solo dispositivo.
              </p>
            </li>
          </ul>

          {
          /* 
          **************************

          Transmision de informacion

          **************************
          */
          }

          <h2 className="estilo-text-1">Modelo OSI y TCP/IP</h2>
          <p>
            Generalmente la información que se trasporta por una red recibe el nombre de datos o paquetes. Los paquetes son entidades de información lógicamente agrupadas que se desplazan entre los distintos equipos como las computadoras. La transmisión y recepción (comunicación) entre equipos que forman parte de una red es demasiada compleja por lo que es necesario estructurar estos mensajes segmentándolos en capas. Estas capas están jerarquizadas y dividen la complejidad haciendo que el envío y recepción de mensajes sea más fácil de controlar y sobre todo que todos los equipos tengan la capacidad de tratarlos.  Para la implementación de capas se usan protocolos que son conjuntos de reglas que gobiernan la comunicación entre entidades de una misma capa, es decir que el protocolo de la capa N de una maquina origen intercambiará información con el protocolo de la capa N de la maquina destino. Hay dos modelos de protocolos estándar que utilizan capas en la comunicación de mensajes y son el modelo OSI  y el TCP/IP. Como se mencionó con anterioridad, estos modelos dividen el mensaje en capas y permiten explicar cómo los datos se comunican de una computadora a otra. Ambos modelos difieren en la cantidad de capas, pero poseen funcionalidades similares.
          </p>
          <p>
            El modelo OSI cuyas siglas significan (Open Systems Interconnection) cuenta con siete capas que son la de aplicación, presentación, sesión, transporte, red, enlace de datos y Física. En cambio, en modelo TPC/IP solo posee 5 porque fusiona algunas de las capas del modelo OSI.
          </p>
          <p><u>MODELO OSI</u></p>
          <ul>
            <li><strong>APLICACIÓN:</strong> Corresponden a las aplicaciones que utilizamos como lo pueden ser WhatSapp, Youtube, correos electrónicos, Instagram, etc. Al escribir un mensaje por ejemplo “Que tal amigo” desde la aplicación y al presionar sobre el botón enviar, la información es preparada y enviada inmediatamente a la capara de presentación.</li>
            <li><strong>PRESENTACIÓN:</strong> En esta capa se determina en que formato está enviando el mensaje. Los formatos que se reciben desde la capa de aplicación pueden ser de varios tipos, por ejemplo, texto, video, imágenes, etc. Para nuestro caso estaríamos enviando el mensaje “Que tal amigo” en formato texto.</li>
            <li><strong>SESIÓN:</strong> La capa de sesión se encarga de establecer una conexión desde la maquina origen quien emite el mensaje a la maquina destino. Se establecen permisos, por ejemplo, al enviar un mensaje desde la aplicación WhatsApp de nuestro celular se inicia una conexión entre el servidor de WhatsApp en donde se negocia si se le conceden los permisos necesarios para poder llevar a cabo la comunicación y transmisión de mensajes entre el equipo origen (nuestro celular) y el equipo destino (celular de nuestro amigo).</li>
            <li>
            <strong>TRANSPORTE:</strong> La capa de transporte tienen la funcionalidad de dividir la información (mensaje) en segmentos. Para nuestro caso el mensaje original “Que tal amigo” se podría dividir en 3 paquetes, p1::[Que  ] p2::[tal ] p3::[amigo].<br/>
              Esta esta capa hay dos tipos de técnicas con las cuales se puede trasportar la información y estos protocolos son TCP y UDP. Para el caso de TCP los paquetes son controlados de forma que no se admitan perdida de datos. Imaginemos que enviáramos la secuencia de bits 101001. Al llegar esta información al equipo destino, esta controlara en su capa de transporte que la secuencia recibida sea la 101001. Por el contrario, si lo recibido fuera 101000 entonces se solicitara a en reenvió del paquete de información. Es decir que el protocolo TCP vuelve a solicitar ese paquete o datos de información hasta que se corrija y se obtenga 101001. Una vez que los datos son iguales se aceptan y se los etiquetan como datos válidos. Este protocolo se utiliza mucho para transacciones bancarias, por ejemplo. El protocolo UDP no realiza un control de integridad de información, es decir que, si un bit de información se pierde o no es correcto, la información es aceptada de igual forma. Este tipo de protocolo es utilizado en enlaces en vivo como audios en vivo, videos en vivo, streaming, etc.  
            </li>
            <li><strong>RED:</strong> La capa de red es la encargada de evaluar y determinar el mejor camino para enviar los paquetes por la red. Es muy común escuchar que los Routers son de capa de red juntamente porque ellos son concebidos para determinar la ruta de envió de información. Otras de las particularidades que tiene la capa de red es la de colocar a cada paquete una cabecera con datos extra para poder determinar y llevar a cabo el control de la trazabilidad del paquete. Por ejemplo, pueden formar parte de estos datos extras la dirección IP del equipo origen y la del equipo destino, un bit de paridad con el cual se controla la integridad de la información que se envía chequeando que los datos enviados desde la computadora origen son los mismos que se reciben en la computadora destino.</li>
            <li><strong>ENLACE DE DATOS:</strong> Esta capa es la encargada de convertir los paquetes de información en bits. Es decir, convierte la información contenida en los paquetes en ceros y unos que es el tipo de datos que entienden las computadoras. Recordemos que es sistema binario utilizados por las computadoras corresponden al voltaje eléctrico 0(0 vol) y 1 (5 vol). De allí el concepto de señales digitales.</li>
            <li><strong>FÍSICA:</strong> Esta capa corresponde el medio físico por el cual se envía o recibe la información. Los ceros y unos son enviadas a través de cables de red, Wifi, señales telefónicas, fibra óptica, radio frecuencias, etc. Como los datos pueden ser conmutados dependiendo de las características del medio por el cual se envíen, es por esta razón que es necesario la utilización de un modem para convertir las señales digitales a analógicas o viceversa.</li>
          </ul>
          <p><u>MODELO TCI/PI</u></p>
          <p>El modelo TCP/IP es un modelo simplificad. Unifica algunas de las capas del modelo OSI pasando de siete capas a solo contener cuatro capas.</p>
          <p>Estas capas son las siguientes:</p>
          <ul>
            <li><strong>APLICACIÓN:</strong> [unifica las capas aplicación, presentación, sesión de modelo OSI]</li>
            <li><strong>TRANSPORTE:</strong></li>
            <li><strong>INTERNET:</strong></li>
            <li><strong>ACCESO A LA RED :</strong> [unifica las capas de enlace de datos y física del modelo OSI]</li>
          </ul>


          <br/>
          <h1>Más sobre redes de computadoras</h1>
          <br/>

          <hr className="line-separacion-2"/>  
          <p align="center">
            <strong className="text-warning">
            Hasta el momento hemos mencionado como se clasifican las redes, su topología, distintos dispositivos que hacen posible la comunicación entre equipos y como se transmite la información con el modelo OSI y TCP/IP. En este apartado se pretende explicar de una forma simple, como las computadoras intercambian mensajes a través de la red de interconexión local o Internet.
            </strong>
          </p>  
          <hr className="line-separacion-2"/>  
          <br/>

          <p>
            Debemos tener en cuenta que solo el hecho de tener varias computadoras conectadas a un switch ya forma una red. 
            Pero existen ciertos elementos que debemos considerar:
          </p>
          <p>
            Todas las maquinas se comunican a través de un protocolo estándar llamado TCP/IP. Ese protocolo es como el canal de comunicación que todos los equipos conectados a una red utilizan mediante su dirección IP (recordemos que la dirección IP es como la dirección de una calle, para nuestro caso sería la dirección en donde se encuentra nuestra computadora). 
          </p>
          <p>
            Actualmente hay dos versiones con las que se está trabajando IPv4 y IPv6. ¿Cuál es la diferencia?, que IP versión 6 posee mayor capacidad de direccionamientos. Sin dirección IP las computadoras no se pueden comunicar. Las maquinas tienen que comunicarse por una dirección.
          </p>
          <p>
            Entonces pueden pasar dos cosas: la primera que asignemos una dirección IP a cada máquina para que se puedan comunicar, la segunda que asignen direcciones IP automáticamente. Por ejemplo, a veces cuando revisamos la dirección IP de nuestras computadoras, ellas de ante mano ya poseen una dirección establecida dentro del rango <strong>169.254.xx.xx</strong>.
          </p>
          <p>
            Esta dirección pertenece a un rango de direcciones de emergencia que proporciona el propio sistema operativo y que recibe el nombre de <strong className="colorblue">APIPA</strong> cuyas siglas significan <i><u>(Direccionamiento Privado Automático del Protocolo de Internet)</u></i>. Esto es porque cuando nuestras computadoras no pueden obtener manualmente una IP porque tal vez no se tenga un servidor DHCP, entonces ellas están preparadas para asignarse una IP automáticamente y poder establecer inmediatamente una comunicación con el resto de los equipos conectados a la red.
          </p>
          <img className="topologias-tipos" src={require("../../assets/images/Red/red1.jpg")} alt="" />

          <p>
            Por lo general cuando uno decide armar una red, no utiliza estos rangos de direcciones, sino que se por el contrario es conveniente armar rango de direcciones específicas. 
          </p>
          <p>
            Es por ello que para lograr esto por lo general agregamos un servidor o router con un servicio que se llama <strong className="colorblue">DHCP</strong>. A ese servicio se le asigna un pool de direcciones, un rango de IPS por ejemplo de la 192.168.9.15 a la 192.168.9.224.
          </p>
          <p>
            Cuando una maquina se conecta a una red lo primero que hace es una negociación pidiendo quien le puede asignar una IP y el DHCP le contesta asignándole una que este dentro del rango anteriormente indicado.
          </p>
          <img className="topologias-tipos" src={require("../../assets/images/Red/red2.jpg")} alt="" />
          
          <p>
            Entonces el servicio DHCP es el que se encarga de proporcionar direcciones IP de forma automática dentro de un rango especifico sin tener que estar poniendo direcciones estáticas.
          </p>
          <p>
            Este servicio de DHCP se puede dar a través de un servidor o a través de los routers configurados para entregar direcciones IP. Con este servicio DHCP garantizo que todas las maquinas tendrán un IP asignada del rango de IPS que mi empresa decida asignar.
          </p>
          <p>
            Ejemplo: en una escuela se puede manejar diferentes rangos de direcciones IP por piso a través del servicio de DHCP (Dinamic Host Control Protocol).
          </p>
          <p>
          Ahora bien, si bien estos números de IP son fácilmente reconocibles por una máquina, para el usuario final resultan ser muy difíciles de memorizar y de trabajar con ellas. 
          Imaginémonos que somos nuevos en nuestro empleo y nuestro jefe nos pide que busquemos determinados documentos que se encuentran en la carpeta con dirección 192.168.65.7/doc y luego los copie a la otra carpeta con dirección 102.168.65.9/facturas.  Es claramente que estas direcciones no son fáciles de recordar y para ellos existe otro servicio denominado <strong className="colorblue">DNS (Domain Name Service)</strong>. 
          </p>
          <img className="topologias-tipos" src={require("../../assets/images/Red/red3.jpg")} alt="" />

          <p>
          Cada computadora cuando la instalamos recibe un nombre (ejemplo OFICINA-1). Es servicio DNS quien se encarga de registrar en una base de datos la dirección IP de la maquina (ejemplo: 192.168.65.9) asociada a su nombre (OFICINA-1). Entonces cuando alguien dentro de la red quiera comunicarse con una maquina en particular (ejemplo de OFICINA-2), entonces OFICINA-1 le preguntara al servicio DNS quien es OFICINA-2. 
          El DNS buscará en su base de datos a OFICINA-2 y si existe le devolverá su dirección IP asociada por ejemplo 192.168.6.16. De esta manera a través de sus direcciones IP tanto OFICINA-1 como OFICINA-2 podrán comunicarse.
          </p>

          <p>
            El DNS en una pieza fundamental en Internet, cuando nosotros escribimos en nuestro navegador la dirección para entrar a mercadolibre no colocamos la dirección IP del servidor de mercado libre sino que en su lugar escribimos en nuestro navegador www.mercadolibre.com.ar.  Entonces se busca en los DNS de internet  la dirección IP asociada a el nombre www.mercadolibre.com.ar. Entonces los nombre nos sirven para llegar más fácil de un equipo al otro.
          </p>
          <p>
            Una comparación del DNS seria la libreta de contacto de los celulares, para llamar a algún amigo/a familiar generalmente marcamos sobre el nombre con el que hemos guardado a este contacto, ejemplo “Primo Raul” cuyo número de celular podría ser 15-9999-9999. Bueno el DNS cumple esa misma función, convertir aquellas direcciones complejas con las que realmente se acceden a los equipos en nombres fáciles de recordar.
          </p>
          <p>
            Todo lo que tiene que ver con Internet se hace a través de los DNS que cuenta con una base distribuida y escalable. 
          </p>
          <p>
            Es importante citar que los DNS no solo forma parte de una red local sino que de igual forma tambien existen DNS repartidos en los 13 servidores raices de Internet. 
            Cuando realizamos una búsqueda para acceder a un sitio en particular desde nuestra computadora, primero se busca la dirección en el DNS local, si no la encuentra entonces inicia la búsqueda sobre los DNS de internet. Hay un DNS publico que nunca se cae y es el de Google 8.8.8.8 (no se cae porque tiene tolerancia a fallos). 
            Los DNS de Google son IPv4 (8.8.8.8) o (8.8.4.4) / IPv6 (2001:4860:4860:8888) o (2001:4860:4860:8844).
          </p>
          <p>
            Ahora bien, para poder salir con nuestra red local a Internet necesitamos dispones de un Router. 
          </p>
          <img className="topologias-tipos" src={require("../../assets/images/Red/red4.jpg")} alt="" />

          <br/>

        </div>

        <Element name="section-abajo">
            {/* posicionamiento al final de la pagina */}
        </Element>
                
      </div>
    );
  }
}
 
export default Security;