import React, { Component } from "react";
 
class Footer extends Component {
  render() {
    return (
      <div>
        <footer className="footer-section">
          <hr/>
            <div className="container">
              <p>Copyright &copy; todos los derechos reservados - Alv. Leandro - 2020</p>
            </div>
        </footer>
      </div>
    );
  }
}
 
export default Footer;