import React, { Component } from "react";
import { Link } from "react-router-dom";

class SectionInfotmation extends Component {
  render() {
    return (
        <div id="informacion">
            <div className="section-information">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 col-md-4">
                            <h3><i className="fa fa-users"></i>Educación Social</h3>
                            <p>La educación social como una práctica de la Pedagogía Social se podría definir como la práctica educativa para contribuir a lograr la armonía, la integración, el equilibrio, la formación de las personas en todos los ámbitos, para así colaborar a su desarrollo e igualdad de oportunidades.</p>
                            <Link to={"/educacionsocial"}>Continuar leyendo...</Link>
                        </div>
                        <div className="col-lg-4 col-md-4">
                            <h3><i className="fa fa-video-camera"></i>Revolución Digital</h3>
                            <p>Es evidente que en la actualidad nos encontramos ante una revolución en materia de comunicación causada por el exceso de información que reproducen los medios tales como la televisión, la radio, periódicos, revistas, y sobre todo por la cantidad de información que fluye por internet. Esta superabundancia de información transforma a la sociedad en cuando a sus ideas, hábitos, costumbres, cambios culturales que traen como consecuencia una revolución digital.</p>
                            <Link to="/revoluciondigital">Continuar leyendo...</Link>
                        </div>
                        <div className="col-lg-4 col-md-4">
                            <h3><i className="fa fa-wrench"></i>Herramientas tecnológicas</h3>
                            <p>Las herramientas tecnológicas tienen como finalidad mejorar tiempos y calidad en el trabajo, además de lograr una distribución y dosificación de recursos de una forma eficaz..</p>
                            <Link to="/herramientastecno">Continuar leyendo...</Link>
                        </div>
                    </div>
                </div>
                <br/>
            </div>
        </div>
    );
  }
}
 
export default SectionInfotmation;