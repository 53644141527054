import React, { Component } from "react";


class SectionAbout extends Component {

  render() {
    return (
    <div id="about">
      <div className="about-ancla"></div>
      <div className="about-background-txt">
          <h2 className="title-about-center">Acerca de mí...</h2>
          <br/>
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-6 about-text-profesion">
              <p className="fa fa-institution"> Analista de sistemas</p><br/>
              <p className="fa fa-institution"> Docente en Disciplinas Informáticas y tecnológicas.</p><br/>
              <p className="fa fa-institution"> Técnico Mecánico.</p><br/>
              <p className="fa fa-info-circle"> Nacionalidad Argentina</p><br/>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-6 about-text-conocimiento">
              <p className="fa fa-check"> Conocimientos en:</p><br/>
              <p className="fa fa-check"> PHP / Perl / Java / C++</p><br/>
              <p className="fa fa-check"> Html / CSS Styles / Javascript</p><br/>
              <p className="fa fa-check"> Jquery / Bootstrap / Angular / ReactJs</p><br/>
              <p className="fa fa-check"> Tecnología backend y frontend</p><br/>
              <p className="fa fa-check"> Scripting Bach/Ksh entornos Unix/Linux.</p><br/>
              <p className="fa fa-check"> Desarrollo en sistemas de procesamiento.</p><br/>
            </div>
            <div className="col-lg-12 col-md-12 col-sm-12">
              <p className="texto-azul">Mi misión: Compartir y acercar conocimientos informáticos a la ciudadanía estudiantil.</p><br/>
            </div>
          </div>                    
      </div>
      <div className="about-background-img" id="about"></div>
    </div>
    );
  }
}
 
export default SectionAbout;