import React, { Component } from "react";
import { Link } from "react-router-dom";

class SectionLinks extends Component {

  render() {
    return (
    <div id="links" className="links-interes">
        <h2 className="links-titulo">Links de interés...</h2>
        <div className="row">
            <div className="links-txt-center col-lg-12 col-md-12 col-sm-12">
            <Link className="item-pagina" to={{ pathname: "https://rockcontent.com/es/blog/software-educativo/" }} target="_blank">"https://rockcontent.com/es/blog/software-educativo/"</Link><br/>
                <Link className="item-pagina" to={{ pathname: "https://www.masscience.com/" }} target="_blank">"https://www.masscience.com/"</Link><br/>
                <Link className="item-pagina" to={{ pathname: "https://www.mustakisencasa.org/" }} target="_blank">"https://www.mustakisencasa.org/"</Link><br/>
                <Link className="item-pagina" to={{ pathname: "https://internetpasoapaso.com/" }} target="_blank">"https://internetpasoapaso.com/"</Link><br/>
                <Link className="item-pagina" to={{ pathname: "https://www.thermofisher.com/blog/cienciaacelerada" }} target="_blank">"https://www.thermofisher.com/blog/cienciaacelerada"</Link><br/>
                <Link className="item-alumnos" to={{ pathname: "/" }} target="_blank">"[Sección Alumnos]"</Link><br/>
            </div>           
        </div>
    </div>
    );
  }
}
 
export default SectionLinks;