import React, { Component } from "react";
import { Link } from "react-router-dom";
import { animateScroll as scroll} from "react-scroll";

import { scroller } from "react-scroll";
import { Element } from "react-scroll";

class Software extends Component {

  constructor(props){
    super(props);
    scroll.scrollToTop(); // posicionarme al inicio de la pagina.
  }
  
  handleClick(event) { // funcion para regresar a la pagina anterior.
    event.preventDefault();    
    const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop) ;        
    window.history.back(scrollToRef);
  }  
  
  ActionIrToSection(parameter, event) { // funcion para posicionarme en una determinada seccion de la misma pagina.
    event.preventDefault();    
    scroller.scrollTo(parameter);
  } 
    
  render() {
    return (
      <div className="software">

        <Element name="section-arriba">
        {/* posicionamiento al comienzo de la pagina */}
        </Element>

        {/* Botones de direccionamiento */}
        <Link to="/#" onClick={this.ActionIrToSection.bind(this, "section-arriba")}><span className="link-arriba fa fa-arrow-circle-up"></span></Link>
        <Link to="/#" onClick={this.handleClick}><span className="link-regresar fa fa-arrow-circle-left"></span></Link>
        <Link to="/#" onClick={this.ActionIrToSection.bind(this, "section-abajo")}><span className="link-abajo fa fa-arrow-circle-down"></span></Link>
       

        <div className="container">
          <h1>Software</h1>

          <p>
          La función básica de una computadora es la de recibir datos, procesarlos y devolver como resultado información útil. 
          Claro que para poder lograr estas actividades necesita de la ayuda del <Link to={"/hardware"} className="portfolio-text">Hardware</Link> que conforma a la computadora. 
          Pero la parte física de la computadora no podría lograr hacer nada si no fuera por un conjunto de instrucciones especialmente 
          desarrolladas para interactuar con el hardware. 
          Los algoritmos (series de instrucciones ordenadas) dan vida y permiten lograr funcionalidad a la computadora.
          </p>
          <p>
            <b>¿Pero que son los Algoritmos?</b>
          </p>
          <p>
          Si nos imaginamos por un momento en los pasos necesarios para plantar un árbol tendremos que: primeramente, se debe de elegir el lugar donde plantar el árbol, aflojar la tierra, cavar un hueco, colocar el árbol en el hueco, cubrir con tierra el tallo hasta que quede al ras del suelo y por último regarlo con un poco de agua.  Como se puede apreciar, esta serie de pasos, reglas o instrucciones ordenadas nos permitirán desarrollar determinada actividad y por supuesto obtener el mejor resultado posible. Entonces a este conjunto finito y ordenado de instrucciones que nos permite resolver un determinado problema o desarrollar una determinada tarea se lo llama Algoritmo.  
          </p>
          <p>
          Los algoritmos son aplicados en todas partes de nuestras vidas cotidianas, al seguir una receta para elaborar una comida, al cambiar la llanta de un automóvil, realizar la carga de un celular, en fin, existe una gran variedad de algoritmos que por lo generar lo realizarnos sin darnos cuenta.  Pues bien, las computadoras también necesitan de estos algoritmos para poder realizar determinadas acciones cuyas instrucciones son convertidas del lenguaje de alto nivel (algoritmo) a lenguaje que entiende la computadora (programas).
          </p>
          <p>
          Programar es escribir el conjunto de instrucciones que conforman el algoritmo en un lenguaje común para el programador y la máquina. 
          Ya hablaremos con mas detalles sobre los programas, ahora debemos centrarnos en conceptualizar lo que es Software. 
          </p>
          <p>
            <b>¿Qué es Software?</b>
          </p>
          <p>
          Las computadoras pueden realizar diversas tareas de forma simultánea y cada una de estas tareas son ejecutadas mediante instrucciones previamente programadas. Lo cierto es que muchas veces para realizar una determinada actividad se requiere de muchos programas y rutinas que trabajen conjuntamente. Al conjunto de rutinas y programas diseñados para realizar acciones y resolver un determinado problema de forma automática a través del sistema informático se lo denomina Software.  
          </p>
          <p>
          Entonces, para conceptualizar el significado de Software tomemos la siguiente definición del cuaderno didáctico de informática general:  
          </p>
          <p>
            <b>
              <i>
              "El software o soporte lógico (en francés logiciel) lo componen todos los programas necesarios para realizar 
              con el ordenador el tratamiento de la información. Así, por ejemplo, son software los programas de tratamiento 
              de textos, las hojas de cálculo, los sistemas operativos, los compiladores y los intérpretes de los lenguajes 
              de programación y cualquier programa escrito por nosotros mismos o por un programador". <sup>1</sup>
              </i>
            </b>
          </p>
          <p>
          Entonces ya sabemos que para que una computadora funciona necesita de una parto fisica (Hardware) y de una parte lógica (Software) que 
          dota de capacidad para que la computadora realice determinados tipos de trabajos. 
          </p>

          <p className="classoft">El Software puede clasificarse en:</p>
          <ul>
            <li>SOFTWARE DE SISTEMA</li> 
            <li>SOFTWARE DE APLICACIÓN</li>
          </ul>

          {/*
          ====================== 
          SOFTWARE DE SISTEMA
          ====================== 
          */}

          <h1 className="tituloH1">Software de Sistema</h1>
          <p>
            Podríamos decir que es el más importante dentro del grupo que conforma al software ya que éste nos permite 
            la comunicación con el Hardware de manera rápida y sencilla. El sistema operativo es uno de los más conocidos 
            encargado de controlar el funcionamiento de la computadora ocultando los detalles de interacción con el hardware. 
            Otro que históricamente era muy nombrado y que en la actualizad ya no se escucha hablar con frecuencia entre la 
            mayoría de los usuarios es el Bios. Si bien el Bios en un hibrido, es decir que no es del todo software ya que 
            también forma parte del hardware, he optado por colocarlo dentro de esta clasificación pues en la actualidad y 
            con el avance de la tecnología se han desarrollado nuevas técnicas para asegurar el funcionamiento y ampliar la 
            seguridad de la computadora mediante el uso de software registrado en una memoria ROM, Fhash o similar. A estos 
            hibridos se los conoce con el nombre de Firmware. Abriéndose paso a su antecesor, 
            UEFI (Unified Extensible Firmware Interface) funciona como una interfaz entre el sistema operativo y el hardware, 
            proporciona nuevas y mejoradas características que amplían aún más la funcionalidad proporcionadas por el ya obsoleto BIOS.
          </p>

          {/*
          ====================== 
          FIRMWARE BIOS/UEFI
          ====================== 
          */}

          <br/>
          <hr className="line-separacion-1"/>
          <div align="center">
            <u className="tituloU">- FIRMWARE -</u>
          </div>
          <br/>

          <img className="capasHarware" src={require("../../assets/images/Software/capasHarware1.jpg")} alt="" />

          <p>
            El término firmware varía en alcance y significado en la industria, así que definámoslo cómo la unión 
            donde el hardware y el software se encuentran y se comunican entre sí. Está constituido en un producto 
            de sistemas integrados y almacenado en una memoria no volátil, como ROM, EPROM, FLASH. La memoria puede 
            estar ubicada dentro o fuera del chip. También se conoce en la industria como software integrado o software 
            de bajo nivel.
          </p>
          <p>
            En el lado del hardware, es una colección de registros direccionables de identificación que son accesibles 
            al firmware a través de lecturas y escrituras. Estos incluyen las interrupciones que notifican al firmware 
            de eventos.
          </p>
          <p>Dentro de la categoría de firmware podemos mencionar a:</p>
          <ul>
            <li><b>BIOS</b> (Basic Input Output System)/ Sistema básico de entrada y salida.</li>
            <li><b>UEFI</b> (Unified Extensible Firmware Interface) / Interfaz de firmware extensible unificada.</li>
          </ul>

          <p>
            <br/>
            <u>BIOS (Basic Input Output System)</u>
          </p>
          
          <p>
            Basic Input/Output System, mejor conocido por las siglas (BIOS), es un software alojado en una memoria 
            no volátil dedicada. Cuando hablamos de “memoria dedicada” nos referimos a un chip dedicado únicamente 
            a ejecutar las instrucciones de software específicas cuya particularidad es que estas instrucciones no 
            pueden sufrir alteraciones dado que este chip de memoria ROM es únicamente de solo lectura. 
          </p>
          <p>
            La función del BIOS es de controlar todas las operaciones básicas de entrada y salida que debe tener una 
            computadora como la configuración de algunos controladores y dispositivos por ejemplo el reconocimiento 
            de discos duros, memorias, orden de arranque de inicio del sistema, y la carga en memoria principal del 
            sistema operativo para que pase a tomar el control de todo el sistema. Si bien es cierto que el BIOS 
            varía de acuerdo a su fabricante, sus funcionalidades son similares.
          </p>
          <p>
          Se pueden instalar distintos tipos de periféricos a una computadora y cada uno puede variar en modelo y 
          marca (fabricante). Aunque sean de la misma aplicación y funcionalidad es muy frecuente que la CPU 
          (unidad central de proceso) deba controlarlos de forma diferente. Es aquí donde el BIOS entra en acción 
          reconociendo y controlando estos dispositivos haciendo que la labor del sistema operativo sea más fácil 
          brindando una capa de independencia entre el Hardware. 
          </p>

          <div className="row">
            <div className="col-lg-4 offset-lg-1 col-md-4 offset-md-1 col-sm-4 offset-ms-1">
              <img className="bios" src={require("../../assets/images/Software/Bios1.jpg")} alt="" />
              <p className="d-flex justify-content-center txt-img">Chip BIOS integrado a la placa base (Motherboard)</p>
            </div>  
            <div className="col-lg-2 col-md-2 col-sm-2">
            </div>  
            <div className="col-lg-4 col-md-4 col-sm-4">
              <img className="bios" src={require("../../assets/images/Software/Bios2.jpg")} alt="" />
              <p className="d-flex justify-content-center txt-img">Software BIOS (Basic Input Output System)</p>
            </div>  
          </div>

          <p>
            <br/>
            <u>UEFI (Unified Extensible Firmware Interface)</u>
          </p>
          <p>
            UEFI cuyas siglas significan Interfaz de firmware extensible unificada, es un estándar que pretende sustituir al ya conocido sistema de arranque que se ha venido utilizando (BIOS).
          </p>
          <p>
            El BIOS posee algunas limitaciones que se fueron agudizando a medida que surgieron nuevos avances en la tecnología del Hardware haciéndolo obsoleto. Principalmente se encuentra ligado a la arquitectura X86 de 16bits compatible con 32 bits “i386”.  Otra de las limitaciones es que solo puede arrancar desde unidades de 2TB (Terabyres) o menos. Básicamente UEFI cumple con las mismas funciones de su antecesor el BIOS, pero con mejoras en las características ya existentes e incorpora nuevas.
          </p>
          <p>
            Ejemplo de estas mejoras son la interfaz gráfica más moderna, sistema de inicio seguro, mayor velocidad de arranque y soporte para unidades (discos duros) de más de 2 TB.
          </p>
          <p>
            Otras de las ventajas que incorpora UEFI es el permitir la conexión a internet lo que implica su actualización 
            de una forma más sencilla. También se incorporan mejoras en la seguridad a través de la funcionalidad Secure Boot. 
            Esta funcionalidad implementada a partir del Windows 8 permite el arranque seguro bloqueando el inicio del sistema 
            operativo ante cualquier software no autentificado como los bootkits que son 
            <Link to="/#" onClick={this.ActionIrToSection.bind(this, "section-aclaraciones")}> malware </Link> que se ejecutan 
            al inicio del sistema operativo con el fin de obtener acceso total a las funciones de la computadora antes que 
            finalice la carga del mismo.
          </p>
          <img className="bios" src={require("../../assets/images/Software/Bios3.jpg")} alt="" />
          <p className="d-flex justify-content-center txt-img">UEFI (Interfaz de firmware extensible unificada)</p>

          {/*
          ====================== 
          SISTEMA OPERATIVO 
          ====================== 
          */}

          <br/>
          <hr className="line-separacion-1"/>
          <div align="center">
            <u className="tituloU">- SISTEMA OPERATIVO -</u>
          </div>
          <br/>

          <img className="capasHarware" src={require("../../assets/images/Software/capasHarware2.jpg")} alt="" />

          <p>
            Una computadora es un sistema complejo en el cual intervienen una serie de elementos indispensables 
            para su funcionamiento como la memoria principal, el procesador, discos duros, impresoras, video cámaras, 
            micrófonos, pantallas, interfaces de red, etc. La lista es inmensamente grande y depende directamente de 
            los avances tecnológicos que vayan surgiendo. Cada dispositivo necesita de controladores, programas que se 
            encargan de su funcionamiento, pero escribir estos programas para que cada elemento funcione de forma óptima 
            no es una tarea fácil y conlleva un nivel de dificultad extremadamente alto.            
          </p>
          <p>
            Es por ello que las computadoras vienen equipadas con una capa de software que se encarga de interactuar 
            directamente con el hardware a través de leguaje de máquina, de forma de poder simplificar la utilización 
            de estos dispositivos y brindar una experiencia cómoda y de alto nivel al usuario. Esta capa de software 
            encargada de gestionar y dirigir todos los servicios y aplicaciones que utiliza un usuario en una computadora 
            recibe el nombre de sistema operativo.            
          </p>
          <p>
            Imaginemos al sistema operativo como una enorme agrupación de aplicaciones y rutinas encargadas de brindar 
            funcionalidad a la computadora y ocultar todos los detalles técnicos al usuario. Un sistema operativo costa 
            de varios módulos y ellos son:            
          </p>
          <ul>
            <li>El administrador de procesos.</li>
            <li>El gestor de memoria.</li>
            <li>El administrador de almacenamiento secundario.</li>
            <li>El gestor de entrada y salida.</li>
            <li>El sistema de protección y seguridad.</li>
            <li>El sistema de comunicación, red.</li>
            <li>Interprete de comandos.</li>
          </ul>
          <p>
            Todos ellos y muchos más dependen de la arquitectura de la máquina y del propio sistema operativo.
          </p>

          <p>Demos una definición formal:</p>
          <p>
            <b>
              <i>
                "Un sistema operativo (SO) es un programa que tienen encomendada una serie de funciones diferentes 
                cuyo objetivo es simplificar el manejo y la utilización de la computadora, haciéndolo seguro y eficiente." <sup>2</sup>
              </i>
            </b>
          </p>

          <p>Entonces un sistema operativo funciona como una plataforma donde todos los programas pueden funcionar. 
            Pero cabe aclarar que la elección de sistema operativo determina el tipo de aplicaciones que se pueden usar.</p>

          <img className="so2"  src={require("../../assets/images/Software/SO2.png")} alt="" />

          <p>
            Existe una gran lista de sistemas operativos en los cuales se pueden destacar los destinados a computadoras 
            personales o de escritorio, para los dispositivos móviles y servidores.
          </p>
          <p>
            Dentro del mercado de sistemas operativos de escritorio los más conocidos son Mac, Windows y Linux. Con respecto 
            a estos dos últimos, a lo largo de la historia, diferentes foros de tecnología han presentado una lucha por destacar 
            cual de ambos es el que brinda la mejor experiencia al usuario, es el más robusto, etc. Lo cierto es que, desde 
            mi opinión, la elección de un sistema operativo está directamente ligada a nuestras necesidades, gustos y preferencias. 
            Hay usuarios que prefieren Windows por su interfaz amigable y lo fácil de usar, por otro lado, tenemos a usuarios más 
            experimentados que prefieren Linux porque es mucho más eficiente en termino de recursos de sistema, porque su nivel 
            de personalización es muy superior, Linux se ha convertido en líder de supercomputación pues cada vez más es la 
            preferencia de muchas compañías para ser instalarlo en sus servidores. Pero aun Windows domina el mercado como 
            software para máquinas de escritorio. 
          </p>
          <img className="lucha-so"  src={require("../../assets/images/Software/lucha_de_SO.png")} alt="" />

          <p>
            A continuación, se presentan algunas ventajas y desventajas que poseen los sistemas operativos Windows y Linux.
          </p>

          <p><u>Linux\Ventajas:</u></p>
          <p>
            Bajo costo: No necesita gastar mucho dinero para obtener licencia ya que sus programas provienen de GNU General Public  License. También puede descargar software de alta calidad siempre que lo desee, sin costo alguno y no necesita preocuparse de que su programa pueda detenerse debido a la versión de trial. También puede instalarlo en muchas computadoras sin pagar.            
          </p>
          <p>
            Rendimiento: Linux proporciona un alto rendimiento en las estaciones de trabajo y en las redes. También ayuda a hacer viejas computadoras suficientes y utilizables de nuevo y también puede manejar muchos usuarios a la vez.
          </p>
          <p>
            Estabilidad: No tiene que reiniciar periódicamente para mantener el rendimiento. Puede manejar gran número de usuarios y no bloquea ni se ralentiza debido a problemas de memoria.
          </p>
          <p>
            Flexibilidad: Se utiliza para aplicaciones de alto rendimiento, aplicaciones de escritorio y aplicaciones integradas. Puede ahorrar espacio en disco instalando los componentes necesarios para un uso en particular. Puede restringir los equipos específicos en lugar de todos los equipos.
          </p>
          <p>
            Seguridad: El aspecto de seguridad de Linux es muy fuerte, ya que es muy seguro y es menos prominente a los virus, incluso si hay un ataque que sería un paso inmediato tomado por los desarrolladores de todo el mundo para resolverlo.
          </p>
          <p>
            Elección: La elección es una de las mayores ventajas de Linux. Da el poder de controlar todos los aspectos del sistema operativo. Las características principales que tiene el control es la apariencia del escritorio por el Administrador de Windows y el kernel.
          </p>
          
          <p><u>Linux\Desventajas:</u></p>
          <p>
            Entendimiento: Para familiarizarse con Linux es necesario tener mucha paciencia y deseo de leer y explorar sobre ello.
          </p>
          <p>
            Software: Linux tiene una selección limitada de softwares disponibles.
          </p>
          <p>
            Facilidad: A pesar de que Linux ha mejorado mucho en la facilidad de uso, pero las ventanas es mucho más fácil.
          </p>
          <p>
            Hardware: Linux no soporta muchos dispositivos de hardware.
          </p>

          <p><u>Windows\Ventajas:</u></p>
          <p>
            Facilidad: Microsoft Windows ha hecho mucho adelanto y cambios que hicieron fácil de usar el sistema operativo. A pesar de que no es el más fácil, es más fácil que Linux.
          </p>
          <p>
            Software: Ya que hay más número de usuarios de Microsoft hay más programas de software, juegos y utilidades para Windows. La mayoría de todos los juegos son compatibles con las ventanas, algunos de los juegos intensivos de CPU y gráficos intensivos también son compatibles.
          </p>
          <p>
            Hardware: Todos los fabricantes de hardware soportarán Microsoft Windows. Debido al gran número de usuarios de Microsoft y al controlador más amplio, se admiten todos los dispositivos de hardware.
          </p>
          <p>
            Extensión de la página principal: Cuando se utiliza un popular programa de diseño web con Windows de alojamiento hace que sea mucho más fácil. Usted no tiene que preocuparse si apoyó o no.
          </p>
          <p>
            Desarrollo: Si planea desarrollar aplicaciones basadas en ventanas, entonces la plataforma Windows es la más sugerida ya que Linux no admite aplicaciones de Windows.
          </p>

          <p><u>Windows\Desventajas:</u></p>
          <p>
            Precio: Microsoft Windows es costoso comparado con Linux ya que cada licencia tienen un precio elevado.
          </p>
          <p>
            Seguridad: En comparación con Linux es mucho más propenso a los virus y otros ataques.
          </p>
          <p>
            Confiabilidad: Tiene que ser reiniciado periódicamente, o hay una posibilidad de colgar el sistema.
          </p>
          <p>
            Software Costo: A pesar de que las ventanas tienen software, juegos gratis la mayoría de los programas costará mucho dinero.
          </p>

          <ul>
            <li>Por otra parte nos toca mencionar a los dispositivos móviles que también cuentan con sistemas operativos, 
            aunque diferentes. Los más conocidos son Google Android, Windows Phone y Apple iOS !.</li>
          </ul>

          <br/>
          <h4 align="left">CLASIFICACIÓN DE LOS SITEMAS OPERATIVOS</h4>
          <br/>
          <p>
            Los sistemas operativos se clasifican acorde a tres criterios:
          </p>

          <p><u>Por el modo de administración de tareas:</u></p>
          <ul>
            <li><b>Monotarea:</b> Este funciona para la realización y ejecución de una sola tarea a la vez, de modo tal que si un programa o bien aplicación está en ejecución, no puede ser abierto otro, ya que se interrumpiría los procesos.</li>
            <li><b>Multitarea: </b>Es aquel que se produce cuando se puede ejecutar una o más aplicaciones, y llevar a cabo varios programas, sin que el proceso de uno interfiera con el otro.</li>
          </ul>

          <p><u>Modo de administración de usuarios:</u></p>
          <ul>
            <li><b>Monousuario: </b>Como su nombre bien lo indica son aquellos que permiten el uso del ordenador de parte de un solo usuario, facilitando solo el desarrollo de las actividades que este tiene a bien ejecutar.</li>
            <li><b>Multiusuario: </b>Son aquellos que permiten el desarrollo de varias actividades por parte de diversos usuarios, es decir, se facilita el desarrollo de distintas aplicaciones por parte de dos o más usuarios. En ocasiones estos equipos pueden ser remotos a una unidad, de modo tal que pueden ser manejados por varias personas, cada cual en sus tareas.</li>
          </ul>

          <p><u>Forma de manejo de recursos:</u></p>
          <ul>
            <li><b>Centralizado: </b>Aquel que facilita el desarrollo de las actividades en un solo ordenador, siendo esto solamente factible en ordenadores simples y con un solo sistema de aplicación de modo tal que este puede solo desarrollar programas en un equipo determinado.</li>
            <li><b>Descentralizado: </b>Son aquellos sistemas que se realizan en diversos equipos o bien ordenadores,  estos por lo general se revisten en centrales operadoras, es decir, un solo equipo mantiene el programa pero por medio de extensiones este puede ser manejado en otros dispositivos. Los sistemas descentralizados permiten manejar las aplicaciones y programas en varios equipos.</li>
          </ul>

          {/*
          ====================== 
          SOFTWARE DE APLICACION 
          ====================== 
          */}

          <br/>
          <hr className="line-separacion-1"/>
          <h1 className="tituloH1">Software de Aplicación</h1>
          <p>
            El Software de Aplicación son los programas diseñados y desarrollados para o por los usuarios para facilitar 
            la realización de tareas específicas en la computadora, celulares, tablets, etc. Estos software pueden ser las 
            aplicaciones ofimáticas (procesador de texto, hoja de cálculo, programa de presentación, sistema de gestión de 
            base de datos). También caben dentro de este grupo de software aplicaciones especializadas para diagnostico médico, 
            aplicaciones educativas, editores de música, aplicaciones de contabilidad, navegadores de internet, etc.
          </p>

          <img className="capasHarware" src={require("../../assets/images/Software/capasHarware3.jpg")} alt="" />

          <p>
            El software de aplicación forma la última capa de toda la jerarquía que vinimos viendo donde se partía del 
            hardware pasando por las diferentes capas como el firmware, hasta llegar a las aplicaciones que van soportadas 
            e instaladas en el sistema operativo.
          </p>    
          <p>
            Esta última capa tiene como finalidad proporcionar nuevas herramientas y características expandiendo de esta 
            forma la funcionalidad que trae el sistema operativo. Estos programas pueden o no venir instalados en la 
            computadora, todo depende del fabricante y sobre todo de las licencias del software. Es decir que la mayoría de 
            ellos son pagos y se debe comprar. 
          </p>
          <p>
            Esto nos lleva a realizar una primera clasificación dentro del grupo de software de aplicaciones, 
            <b>el software comerciales o libres</b>.
          </p>
          <p>
            El Software libre es aquel que una vez obtenido puede ser usado, copiado, estudiado, modificado y redistribuido 
            libremente. Entiéndase por libre al que el mismo puede estar disponible gratuitamente en Internet o a precio del 
            coste de del producto; sin embargo, no es obligatorio que sea así y, aunque conserve su carácter de libre, 
            puede ser vendido comercialmente e incluir su código fuentes.
          </p>
          <p>
            Por otro lado, el Software comercial es justamente aquel que fue diseñado para ser vendido o recibir algún tipo 
            de remuneración por su uso (licencias).
          </p>
          <p>
            La principal característica de un software comercial es generar ganancias a las compañías que los desarrollaron 
            y que perduren en el tiempo con el objetivo de hacerlos más rentables con posibilidad de actualizarlos. Una de las 
            ventajas de este tipo de software es su adquisición a través de su distribuidor, del soporte técnico que ofrecen y 
            las garantías de seguridad por ser muchas veces pertenecientes a compañías reconocidas en el mercado del software.
          </p>
          <p>
            Estas aplicaciones son pensadas para solucionar los problemas de una tarea precisa y en la mayoría de los casos 
            buscan la compatibilidad con la mayoría del hardware. Pero como se mencionó en otra oportunidad, no todos los 
            softwares pueden ser instalados y ejecutados en todos los sistemas operativos. Cada software es desarrollado y 
            pensado para ser compatible con un determinado sistema operativo base. Existen algunas técnicas para poder ejecutar 
            ciertas aplicaciones en distintos sistemas operativos, pero eso es tema a estudiar en otra oportunidad.
          </p>


          <img  src={require("../../assets/images/Software/softaplicacion.jpg")} alt="" />

          <p>
            Ejemplos de algunas de las más conocidas aplicaciones comerciales son el procesador de texto (Word) y las hojas de 
            cálculos (Excel) de Microsoft, PowerPoint, Adobe Illutrator, Adobe InDesign, Adobe Photoshop, CorelDraw, 
            Corel Photo Paint, etc. Pero estas aplicaciones generalmente vienen integradas en un mismo paquete al cual se le 
            suele llamar Suites.  
          </p>

          

          <br/>
          <div align="center">
            <u className="tituloU">- SOFTWARE DE PROGRAMACIÓN -</u>
          </div>
          <br/>

          <p>
            Dentro del inmenso grupo de Software de aplicación que existe en el mercado tenemos los destinados a desarrollo, 
            es decir los que se utilizan para programar y desarrollar nuevas aplicaciones. Esta clase de software permiten 
            crear nuevas aplicaciones o modificar las ya existentes codificándolas en lo que se conoce como lenguaje de 
            programación.
          </p>
          
          <p>
            Algunos ejemplos de este tipo de software son: Asembler, Lisp, QBasic, Visual Studio, Python, Ruby, Ansi C, C++, Java, Perl, etc.
          </p>    
          
          <img className="soft_prog" src={require("../../assets/images/Software/soft_prog.png")} alt="" />

          <p>
            La finalidad de estos softwares es de poder resolver algún problema en particular por medio de las computadoras. 
            Nos permiten escribir el algoritmo mediante un lenguaje natural que entiende el programador para obtener como 
            producto final el programa en un lenguaje que puede entender la máquina.
          </p>

          <p>
            Entonces parte de la tarea de estos softwares de programación es primero permitirnos el diseño de soluciones a 
            problemas específicos en donde se involucra por un lado el análisis del problema y por el otro la programación 
            propiamente dicha.
          </p>
          <p>
            Cada software de programación están diseñado para una metodología de programación. En la resolución de los 
            algoritmos se pueden utilizar la programación modular o estructurada.
          </p>
          <p>
            La programación modular se basa en la realización de descomposiciones sucesivas del algoritmo que queramos 
            llevar a cabo. Estas descomposiciones del código describen el conjunto de instrucciones progresivas que formaran 
            el programa final.
          </p>
          <p>
            Con respecto a la programación estructurada el algoritmo se organiza en funciones o subrutinas. A medida que un 
            algoritmo aumenta su tamaño también lo hace su complejidad para poder mantener un seguimiento de su propósito. 
            Es por esta razón que el código se organiza en estructuras para poder facilitar su lectura e interpretación.
          </p>

          <p>
            Los softwares de programación se utilizan para dejar volar la imaginación de los programadores y desarrollar las 
            aplicaciones a medida. Estas aplicaciones no solo son realizadas para aplicaciones de escritorio, sino que también 
            son expandidas a tecnologías web.</p>
            
          <p>
            Sin más esta página fue desarrollada con tecnología  React Js, biblioteca escrita en JavaScript.
          </p>
          <img className="soft_prog" src={require("../../assets/images/Software/soft_prog_2.jpg")} alt="" />

          <p>
            Dentro de la programación estructurada existen tres tipos:
          </p>
          <ul>
            <li><b>Estructural secuencial:</b> las instrucciones se ejecutan en el orden que se han codificado.</li>
            <li><b>Estructural de decisión:</b> existen condiciones que de acuerdo a que, si se cumplen o no, provocan la ejecución de partes diferentes del programa.</li>
            <li><b>Estructura repetitiva: </b>la ejecución de un grupo de instrucciones se repite un número determinado de veces. El fragmento de código se itera hasta que alguna condición de por terminada su iteración.</li>
          </ul>

          <br/>
          <p>Un ejemplo muy básico de un algoritmo para inferir si un número es cero y su representación en lenguaje de programación C.</p> 

          <p>Algoritmo:</p>
          <p className="code-algoritmo">COMIENZO</p>
          <p className="code-algoritmo">INGRESAR "Ingrese un número:", numero</p>
          <p className="code-algoritmo">SI numero &#62; 0 ENTOCES IMPRIMIR "cero" SINO IMPRIMIR "no es cero"</p>
          <p className="code-algoritmo">FIN</p>

          <br/>
          <p>Algoritmo codigicado en lenguaje de programacion C:</p>  
          <p className="code-c">#include &#60;stdio.h&#62;</p>   
          <p className="code-c">int main(void) &#123;</p>
          <p className="code-c">&nbsp;&nbsp; printf("Ingrese un número:");</p>
          <p className="code-c">&nbsp;&nbsp; scanf("%d", &num);</p>
          <p className="code-c">&nbsp;&nbsp; if(nume == 0)&#123;</p>
          <p className="code-c">&nbsp;&nbsp;&nbsp;&nbsp; printf("cero");</p>
          <p className="code-c">&nbsp;&nbsp; &#125; else &#123;</p>
          <p className="code-c">&nbsp;&nbsp;&nbsp;&nbsp; printf("no es cero");</p>
          <p className="code-c">&nbsp;&nbsp; &#125;</p>
          <p className="code-c">&#125;</p>

          <hr className="line-separacion-1"/>
          <br/>
          <br/>
          <br/>
        </div>


        <Element name="section-abajo">
            {/* posicionamiento al final de la pagina */}
        </Element>


      
        {/* REFERENCIAS */}
        <Element name="section-aclaraciones">
          <p><u className="titulo-referencias">Referencias/fuentes/citas:</u></p>
          <p className="fuentes-referencias"><b>*Malware:</b> El Malware o “software malicioso” es un término amplio que describe cualquier programa o código malicioso que es dañino para los sistemas.</p>
          <p className="fuentes-referencias">1. (Agustín Cernuda del Río y Daniel Gayo Avello, editores Informática General
  Departamento de Informática - Universidad de Oviedo, Febrero 2006) http://di002.edv.uniovi.es/~cueva/publicaciones/monografias/48_InformaticaGeneral.pdf</p>
          <p className="fuentes-referencias">2. Sistemas operativos. una visión aplicada (Jesus Carretero Pérez,Félix García Carballeira, Pedro de Miquel Anasagasti, Fernando Pérez Costoya).</p>
          <p className="fuentes-referencias">Hardware/Firmware Interface Design (Gary Stringham).</p>
        </Element>
      </div>
    );
  }
}
 
export default Software;