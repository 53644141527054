import React, { Component } from "react";
import { Link } from "react-router-dom";
import { animateScroll as scroll} from "react-scroll";

import { scroller } from "react-scroll";
import { Element } from "react-scroll";

class HistoryComputers extends Component {

  constructor(props){
    super(props);
    scroll.scrollToTop(); // posicionarme al inicio de la pagina.
  }
  
  handleClick(event) { // funcion para regresar a la pagina anterior.
    event.preventDefault();    
    const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop) ;        
    window.history.back(scrollToRef);
  }  
  
  ActionIrToSection(parameter, event) { // funcion para posicionarme en una determinada seccion de la misma pagina.
    event.preventDefault();    
    scroller.scrollTo(parameter);
  }     

  render() {
    return (
      <div className="historycomputers">

        <Element name="section-arriba">
        {/* posicionamiento al comienzo de la pagina */}
        </Element>

        {/* Botones de direccionamiento */}
        <Link to="/#" onClick={this.ActionIrToSection.bind(this, "section-arriba")}><span className="link-arriba fa fa-arrow-circle-up"></span></Link>
        <Link to="/#" onClick={this.handleClick}><span className="link-regresar fa fa-arrow-circle-left"></span></Link>
        <Link to="/#" onClick={this.ActionIrToSection.bind(this, "section-abajo")}><span className="link-abajo fa fa-arrow-circle-down"></span></Link>
       

        <div className="container">
          <h1>Evolución de las computadoras</h1>
          <p>
            Las computadoras han evolucionado en un periodo relativamente corto. 
            Las necesidades de poder disponer de información con grandes volúmenes de datos y 
            en tiempos de acceso muy cortos han impulsado al desarrollo de nuevas tecnologías 
            y estrategias. Un ejemplo de estos acontecimientos se puede ver reflejado en el 
            teléfono móvil.  A mediados de  (1973) nacía el primer teléfono celular el cual 
            pesaba 2 kilos. El creador del concepto de teléfono de mano fue Martin Cooper que 
            realizo su primera llamada en una calle de Nueva York. Hoy en la actualidad la 
            industria de telecomunicaciones se ha extendido a lo largo y ancho de nuestro 
            planeta.  Las computadoras por su parte, han sufrido cambios en cuanto a sus 
            capacidades de almacenamiento y presentación de la información buscando 
            flexibilidad para adaptarse a la forma en la que trabajan las personas y organizaciones.             
          </p>
          <u>Antecedentes históricos</u>
          <p>
            Para diferenciar las computadoras por su arquitectura, capacidad de cómputo y tipo
             de componentes, se habla de generaciones de computadoras.
          </p>
          <p>
            Las computadoras se pueden clasificar en 6 generaciones pero cabe aclarar que en la 
            actualidad se están explorando nuevos conceptos, ciencias y tecnologías que pretenden 
            la finalización de esta generación de computadoras binarias abriendo un nuevo camino 
            hacia la generación de computadoras cuánticas donde la unidad mínima de medida pasara 
            de ser bit a qubit. Como aún las computadoras están en pleno desarrollo dejaremos este 
            fabuloso mundo de las computadoras cuánticas para otro apartado.
          </p>
          {/* Primera Generacion */}
          <b><u>Computadoras de 1° generación (1951 – 1955):</u></b>
          <p>
            Las computadoras de esta generación se construyeron con relevadores electromecánicos 
            (Mark1), o tubos de vacío (ENIAC).
          </p>
          <ul>
            <li>Grandes y costosas.</li>
            <li>Generaban mucho calor.</li>
            <li>Los datos se introducían mediante cintas o tarjetas perforadas.</li>
            <li>La programación se realizaba en lenguaje máquina.</li>
          </ul>
          <p>
            Durante muchos años y hasta la actualidad, la arquitectura de las computadoras modernas, 
            es conocida como "Arquitectura Von Neumann" en relación al científico que trabajó en la 
            que habría sido la primera computadora programable, la ENIAC. Pero la ENIAC no fue la 
            primera computadora ni Von Neumann el primer creador de dicha arquitectura.
          </p>
          <p>
            La primera computadora programable fue la "Colossus", el principal reconocimiento debería 
            hacerse tanto a Alan Turing como a "Bill" Tutte y Joan Clarke que trabajaron en el desarrollo 
            de la Colossus, que fue decisiva para descifrar las comunicaciones de la Alemania nazi en 
            la segunda guerra mundial.
          </p>
          <p>
            La computadora ENIAC es una clásica representante de la primera generación. Estaba construida con tubos de vacío.
          </p>
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-6">
              <img className="img-history-computers" src={require("../../assets/images/portfolio/eniac.jpg")} alt="" />
            </div>
            <div className="col-lg-6 col-md-6 col-sm-6">
              <img className="img-history-computers" src={require("../../assets/images/portfolio/tarjetas.png")} alt="" />
            </div>
          </div>  
          <p>
            La primera generación de computadoras <sub>(1951-1955)</sub> estaba compuesta por <b>válvulas de vacío y relés electromagnéticos</b>.
          </p>
          <p>La forma de explotación de los datos era <b>secuencial</b>: hasta que no terminaba 
          un proceso no se podía comenzar otro. Los datos y las instrucciones secuenciales eran 
          ingresados mediante <b>cintas perforadas</b> de papel; más tarde, a través de tableros 
          con conexiones externas y, luego, por medio de <b>tarjetas perforadas</b>.
          </p>
          <p>En estos equipos, la capacidad de almacenamiento de datos era precaria; la evolución 
            de la técnica contribuyó a mejorarla y a ampliarla. Los resultados se visualizaban 
            mediante cintas de papel, tarjetas perforadas o a través de una pantalla.
          </p>
          <p>Por otro lado, se advierte claramente que el uso de la computadora traspasó los límites 
            de la Matemática para adaptarla a los fines comerciales.
          </p>

          {/* Segunda Generacion */}
          <b><u>Computadoras de 2° generación (1955 – 1964):</u></b>
          <p>Esta generación se computadoras se caracterizó por la sustitución de las válvulas de vacío por los <b>transistores</b>.</p>
          <p>Las máquinas eran más pequeñas y más veloces.</p>
          <p>Otro aspecto de destacar en  esta generación son el desarrollo de:</p>
          <ul>
            <li>Instrumentos de almacenamiento de la información en forma  magnética.</li>
            <li>Dispositivos de entrada y salida de gran velocidad de transmisión: lectora de fichas perforadas  e impresoras.</li>
            <li>Lenguajes de programación: Cobol, Algol y Lisp.</li>
            <li>o	En este período se usó, por primera vez, el concepto de memoria virtual, como una forma de extender la memoria central. Nace la informática como la disciplina del tratamiento automático de la información.</li>
          </ul>
          <img src={require("../../assets/images/portfolio/transistores.png")} alt="" />
          <br />
          {/* Tercera Generacion */}
          <b><u>Computadoras de 3° generación (1964 – 1979):</u></b>
          <p>
            El circuito integrado <b>(chip)</b> y el microcircuito son los elementos que permiten hablar de una tercera generación de computadoras.
          </p>
          <p>
            Las computadoras de este período son más pequeñas  y veloces que las de la generación 
            anterior (su velocidad de trabajo se mide en  nanosegundos, es decir, milmillonésimas 
            de segundo). Se caracterizan por la aparición de nuevos mecanismo para la  interrupción 
            de programas  y para la extensión de la memoria.
          </p>
          <p>
            En el transcurso de esta generación se desarrollaron:
          </p>

          <ul>
              <li>Periféricos de entrada y de salida más eficientes.</li>
              <li>Dispositivos y programas orientados al control de procesos industriales, los análisis clínicos, la enseñanza, etcétera.</li>
          </ul>

          <p>
            La aparición del circuito integrado permite tener un gran número de transistores alojados en una misma pastilla.
          </p>
          <p>
            El circuito consta de muchos elementos electrónicos, como resistencias, condensadores, 
            diodos y transistores, que se colocan sobre una pastilla de silicio y se diseñan con un 
            propósito definido.
          </p>
          <p>Se incorporan los sistemas operativos.</p>
          <p>Se inicia el teleproceso. Teleproceso se refiere al procesamiento de datos provenientes de terminales en una unidad central.</p>
          <p>Los datos se almacenan en cintas y discos magnéticos.</p>
          <p>Se introducen las memorias intermedias ultra rápidas (caché).</p>
          <p>Comienza la miniaturización y baja el consumo de energía eléctrica.</p>
          <img src={require("../../assets/images/portfolio/circuito_integrado.png")} alt="" />
          <br/>
          {/* Cuarta Generacion */}
          <b><u>Computadoras de 4° generación (1971 – 1984):</u></b>
          <p>Está marcada por la aparición, en 1971, del primer microprocesador fabricado por la empresa Intel Corporation, ubicada en Silicon Valley, en el estado de California en los estados unidos. El microprocesador (chip) de 4 bits se conoce como "4004". </p>
          <p>En 1977 surgen las primeras microcomputadoras (Apple computer, radio shack, commodore, sinclair, etc.)</p>  
          <p>IBM se incorpora a este mercado en 1981, con la computadora IBM-PC con procesador 8088 de Intel y 16 kb en la memoria principal. (Sistema operativo  MS-DOS)</p>
          <p>En 1984 aparece la IBM AT, con procesador Intel 80286 y reloj interno a 16 MHz.</p>
          <img src={require("../../assets/images/portfolio/micro4004.jpg")} alt="" />
          <p>Desde su fundación la empresa Apple ha marcado el rumbo en el desarrollo de las 
            computadoras de escritorio de interfaz gráfica. (En 1983 presenta a lisa y un año 
            después produce la Macintosh. con Sistema Operativo de ambiente gráfico y ratón o 
            mouse).
          </p>
          <p>Los de memoria masiva adquieren gran capacidad.</p> 
          <p>Se crean las redes de transmisión de datos (telemática).</p>
          <p>Los lenguajes de alto nivel se vuelven más complejos.</p>
          <p>El sistema operativo ms-dos de Microsoft se instala en la mayor parte de las pcs.</p>
          <p>Se crean las memorias virtuales, aparecen los paquetes integrados de 
            Software, surgen los videos juegos.</p> 
          <img src={require("../../assets/images/portfolio/compuIBM.png")} alt="" />
          <br/>
          {/* Quinta Generacion */}
          <b><u>Computadoras de 5° generación (1984):</u></b>
          <p>Aparece la microelectrónica.</p> 
          <p>El software para actividades profesionales asistidas por computadora. </p>
          <p>La estructura cliente-servidor</p>
          <p>Los lenguajes que integran objetos</p>
          <b><u>Sistemas expertos:</u></b>
          <p>Surgen las redes neurales, la teoría del caos, las curvas fractales, las fibras ópticas, las telecomunicaciones y los satélites.</p>
          <p>En 1982 Seymour Cray crea la primera supercomputadora con capacidad de procesamiento en paralelo.</p>
          <p>En el mismo año el gobierno japonés anuncia el proyecto de “inteligencia artificial” para que las computadoras puedan reconocer voz e imagen y se comuniquen en lenguaje natural.</p>  
          <p>El desarrollo de la red mundial de computadoras Internet y de la Word Wide Web, ha proporcionado grandes beneficios a las empresas de todos los tamaños.</p>
          {/* Sexta Generacion */}
          <b><u>Computadoras de 6° generación (a partir de los 90's):</u></b>
          <p>Las computadoras de esta época tienen cientos de microprocesadores vectoriales, que les permiten realizar más de un billón de operaciones aritméticas de punto flotante por segundo (teraflops).</p>
          <p>Las redes del área mundial (WAN) seguirán creciendo y tendrán acceso a los medios de comunicación a través de fibra óptica y satélites.</p>
          <p>En la actualidad la tecnología se encuentra en un constante  desarrollo, concentrándose en la inteligencia artificial, la holografía.</p>
          <p>En cuanto a las computadoras podemos citar los desarrollo sobre computadoras cuánticas. Esta es un paradigma totalmente distinto a lo 
            que conocemos sobre las computadoras clásicas, se basa en el uso de qubit el cual permite contener dos estados posible 0 o 1, similar al 
            bit de las computadoras clásicas, pero con la salvedad que el qubit puede encontrarse en un estado de superposición cuántica, es decir 
            que puede contener una combinación de estos dos estado.</p>

          <Link to={"/computersfuture"} className="portfolio-text">* Leer más sobre computadoras del futuro...</Link>
          <br/>
          <Link to={"/hardware"} className="portfolio-text">* Leer más sobre Hardware...</Link>
          <br/>
          <Link to={"/software"} className="portfolio-text">* Leer más sobre Software...</Link>
          <br/>


        </div>

        <Element name="section-abajo">
            {/* posicionamiento al final de la pagina */}
        </Element>
                
      </div>
    );
  }
}
 
export default HistoryComputers;