import React, { Component } from "react";

class SectionReflextionTechnology extends Component {
  render() {
    return (
        <div>
            <div className="section-reflection-on-tecnology">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <br/>
                            <h2>Educación ciencia y tecnología </h2>
                            <br/>
                            <p>La problemática que presenta el  uso de las TICx está más allá de los problemas particulares de la enseñanza y del aprendizaje dado que se ha convertido en una dieta cognitiva para la sociedad. Estamos ante constantes cambios en la evolución de las TICx convirtiendo a la sociedad en socios tecnológicos; quien no usa o es usado por ella no pertenece al sistema.</p>
                            <p>El uso de las TICx en la educación deben considerarse como un territorio potencial de colaboración en el que pueden desarrollarse otras variantes de la actividad de enseñanza y aprendizaje, no mejor ni peores pero si lógicamente diferentes.</p>
                            <br/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
  }
}
 
export default SectionReflextionTechnology;