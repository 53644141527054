import React, { Component } from "react";
import { Link } from "react-router-dom";

class SectionPortfolio extends Component {

  render() {

    return (
        <div id="porfolio">
            <div className="section_porfolio">
                <div className="container">

                    <div className="row mb-2">

                        {/* Nanotecnología */}
                        <div className="col-md-6">
                            <div className="row no-gutters border rounded overflow-hidden flex-md-row mb-4 shadow-sm h-md-255 position-relative">
                                <div className="col p-2 d-flex flex-column position-static">
                                <strong className="d-inline-block mb-2 text-warning">Nanotecnología</strong>
                                <div className="mb-1 text-muted portfolio-text">Enero 12</div>
                                <p className="card-text mb-auto portfolio-text">Decodificar, analizar y reconfigurar las propiedades de la materia a escala atómica es una buena alternativa que incorpora valor agregado a los procesos y productos ya existentes como también a la creación de nuevos materiales.</p>
                                <Link to={"/nanotechnology"} className="portfolio-text">Continuar leyendo...</Link>
                                </div>
                                <div className="col-auto d-none d-lg-block">
                                <img className="portfolio-img"  src={require("../../assets/images/portfolio-nanotecnologia.jpg")} alt="" />
                                </div>
                            </div>
                        </div>

                        {/* computadoras de la historia */}
                        <div className="col-md-6">
                            <div className="row no-gutters border rounded overflow-hidden flex-md-row mb-4 shadow-sm h-md-250 position-relative">
                                <div className="col p-1 d-flex flex-column position-static">
                                <strong className="d-inline-block mb-2 text-success">Historia de las computadoras</strong>
                                <div className="mb-1 text-muted portfolio-text">Marzo 11</div>
                                <p className="mb-auto portfolio-text">Descubre los momentos que ayudaron a construirlas y la historia detras de cada generación para llegar a lo que hoy se conoce como computadoras.</p>
                                <Link to={"/historycomputers"} className="portfolio-text">Continuar leyendo...</Link>
                                </div>
                                <div className="col-auto d-none d-lg-block">
                                <img className="portfolio-img" src={require("../../assets/images/portfolio-eniac.jpg")} alt="" />
                                </div>
                            </div>
                        </div>

                        {/* computadoras del futuro */}
                        <div className="col-md-6">
                            <div className="row no-gutters border rounded overflow-hidden flex-md-row mb-4 shadow-sm h-md-250 position-relative">
                                <div className="col p-1 d-flex flex-column position-static">
                                <strong className="d-inline-block mb-2 text-success">Computadoras del futuro</strong>
                                <div className="mb-1 text-muted portfolio-text">Marzo 02</div>
                                <p className="mb-auto portfolio-text">En desarrollo.</p>
                                <Link to={"/computersfuture"} className="portfolio-text">Continuar leyendo...</Link>
                                </div>
                                <div className="col-auto d-none d-lg-block">
                                <img className="portfolio-img" src={require("../../assets/images/portfolio-dwave.jpg")} alt="" />
                                </div>
                            </div>
                        </div>  

                        {/* redes y seguridad */}
                        <div className="col-md-6"> 
                            <div className="row no-gutters border rounded overflow-hidden flex-md-row mb-4 shadow-sm h-md-250 position-relative">
                                <div className="col p-1 d-flex flex-column position-static">
                                <strong className="d-inline-block mb-2 text-success">Redes/Seguridad</strong>
                                <div className="mb-1 text-muted portfolio-text">Mayo 20</div>
                                <p className="mb-auto portfolio-text">La tecnología provoca cambios trascendentales en nuestra sociedad y la creciente cantidad de información que manejamos hacen que necesitamos de forma continua adaptarnos y crear nuevas formas de comunicarnos.</p>
                                <Link to={"/security"} className="portfolio-text">Continuar leyendo...</Link>
                                </div>
                                <div className="col-auto d-none d-lg-block">
                                <img className="portfolio-img" src={require("../../assets/images/portfolio-security.jpg")} alt="" />
                                </div>
                            </div>
                        </div>  

                        {/* hardware */}
                        <div className="col-md-6"> 
                            <div className="row no-gutters border rounded overflow-hidden flex-md-row mb-4 shadow-sm h-md-250 position-relative">
                                <div className="col p-1 d-flex flex-column position-static">
                                <strong className="d-inline-block mb-2 text-success">Hadware/Arquitectura</strong>
                                <div className="mb-1 text-muted portfolio-text">Agosto 16</div>
                                <p className="mb-auto portfolio-text">Los cambios tecnológicos, la evolucionan de dispositivos electrónicos son y serán pilares fundamentales para  el desarrollo de nuevas generaciones de computadoras y dispositivos para el tratamiento de datos.</p>
                                <Link to={"/hardware"} className="portfolio-text">Continuar leyendo...</Link>
                                </div>
                                <div className="col-auto d-none d-lg-block">
                                <img className="portfolio-img" src={require("../../assets/images/portfolio-hadware.jpg")} alt="" />
                                </div>
                            </div>
                        </div>   

                        {/* software */}
                        <div className="col-md-6"> 
                            <div className="row no-gutters border rounded overflow-hidden flex-md-row mb-4 shadow-sm h-md-250 position-relative">
                                <div className="col p-1 d-flex flex-column position-static">
                                <strong className="d-inline-block mb-2 text-success">Software</strong>
                                <div className="mb-1 text-muted portfolio-text">Septiembre 10</div>
                                <p className="mb-auto portfolio-text">Programar es escribir el conjunto de instrucciones que conforman el algoritmo en un lenguaje común para el programador y la máquina.</p>
                                <Link to={"/software"} className="portfolio-text">Continuar leyendo...</Link>
                                </div>
                                <div className="col-auto d-none d-lg-block">
                                <img className="portfolio-img" src={require("../../assets/images/portfolio-software.jpg")} alt="" />
                                </div>
                            </div>
                        </div>            
                    </div>



                    {/* Bloque diseño anterior  */}
                    {/* <div className="row">
                        <div className="col-lg-4 col-md-4 col-sm-6">
                            <Link to={"/historycomputers"}>    
                                <img className="portfolio-item" src={require("../../assets/images/portfolio-eniac.jpg")} />
                            </Link>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-6">
                            <Link to={"/computersfuture"}>
                                <img className="portfolio-item" src={require("../../assets/images/portfolio-dwave.jpg")} />
                           </Link>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-6">
                            <Link to={"/security"}>
                                <img className="portfolio-item" src={require("../../assets/images/portfolio-security.jpg")} />
                            </Link>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-6">
                            <Link to={"/nanotechnology"}>
                                <img className="portfolio-item" src={require("../../assets/images/portfolio-nanotecnologia.jpg")} />
                            </Link>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-6">
                            <Link to={"/hardware"}>
                                <img className="portfolio-item" src={require("../../assets/images/portfolio-hadware.jpg")} />
                            </Link>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-6">
                            <Link to={"/software"}>
                                <img className="portfolio-item" src={require("../../assets/images/portfolio-software.jpg")} />
                            </Link>
                        </div>
                    </div> */}
                    
                </div>               
            </div>
        </div>
    );
  }
}
 
export default SectionPortfolio;